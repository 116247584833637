
import { type Either, right, left } from "fp-ts/Either"

import HttpClient from "~/lib/core/http/http_client";
import { CustomStorage } from '~/lib/core/utils/custom_storage'
import { AppFailure, Failure, ServerFailure } from "~/lib/core/error/failure";
import type { PublicCfg } from "~/lib/core/plugins/services";
import { CartItem } from "../models/cart_item_model";
import type { Product } from "~/lib/features/collections/data/models/product_model";
import { Offer } from "../models/offer_model";
import { API_Endpoints } from "~/lib/core/configs/api_endpoints";
import { Cart } from "../models/cart_model";
import type { CartFilters } from "../models/cart_filters_model";



export abstract class CartRepository {
    abstract fetchOffers(): Promise<Either<Failure, Offer[]>>
    abstract applyOffersDiscounts(cart: Cart): Promise<Either<Failure, Cart>>
    abstract getCartItemFromProduct(product: Product): Promise<Either<Failure, CartItem>>
    abstract getStoredCartsLocal(): Promise<Either<Failure, Cart[]>>
    abstract saveCartsLocal(carts: Cart[]): Promise<Either<Failure, boolean>>
}


export class CartRepositoryImpl implements CartRepository {

    constructor(private _httpClient: HttpClient, private publicCfg: PublicCfg) { };

    async fetchOffers(): Promise<Either<Failure, Offer[]>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.offersFetchOffers],
                    body: {},
                    withAuth: true
                }
            )

            if (response.data.message.status == 200) {
                let offers: Offer[] = []

                response.data.message.data.forEach((_offers: Object) => {
                    offers.push(Offer.fromJson(_offers, this.publicCfg) as Offer)
                })

                return right(offers)
            } else {
                return left(new ServerFailure(response.data.message.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }

    async applyOffersDiscounts(cart: Cart): Promise<Either<Failure, Cart>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.offersApplyOffersDiscounts],
                    body: { cart },
                    withAuth: true
                }
            )

            if (response.data.message.status == 200) {
                let cart = Cart.fromJson(response.data.message.data, this.publicCfg) as Cart
                return right(cart)
            } else {
                return left(new ServerFailure(response.data.message.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }


    async getCartItemFromProduct(product: Product): Promise<Either<Failure, CartItem>> {
        try {
            const cartItem = CartItem.empty().copyWith({
                item_code: product.item_code,
                item_name: product.item_name,
                image: product.image,
                price_list_rate: product.price_list_rate,
                stock_uom: product.stock_uom,
                actual_qty: product.actual_qty

            })
            return right(cartItem)
        } catch (e) {
            return left(new AppFailure("failed to convert Product to CartItem"));
        }
    }

    async getStoredCartsLocal(): Promise<Either<Failure, Cart[]>> {
        try {
            // return right([Cart.empty()])
            const jsonCarts = CustomStorage.read("epc-carts")
            if (jsonCarts) {
                const _carts = Cart.fromJson(jsonCarts, this.publicCfg)
                return right(_carts as Cart[])
            } else {
                return right([Cart.empty()])
            }
        } catch (e) {
            return left(new AppFailure("failed to convert save carts"));
        }
    }

    async saveCartsLocal(carts: Cart[]): Promise<Either<Failure, boolean>> {
        try {
            const cartsJson = Cart.toJson(carts)
            
            CustomStorage.write("epc-carts", cartsJson)
            
            return right(true)
            
        } catch (e) {
            return left(new AppFailure("failed to save carts"));
        }
    }
}