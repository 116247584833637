import type { PublicCfg } from "~/lib/core/plugins/services"
import { CartItem } from "./cart_item_model"
import { Offer } from "./offer_model"

interface ICart {
    validated: boolean
    customer: string
    customer_name: string
    conversion_rate: number
    selling_price_list: string
    currency: string
    price_list_currency: string
    total_qty: number
    net_total: number
    grand_total: number
    total: number
    rounded_total: number
    total_taxes_and_charges: number
    apply_discount_on: string
    additional_discount_percentage: number
    discount_amount: number

    items: CartItem[]
    cartOffers: Offer[]
}
  
export class Cart implements ICart {
    validated!: boolean
    customer!: string
    customer_name!: string
    conversion_rate!: number
    selling_price_list!: string
    currency!: string
    price_list_currency!: string
    total_qty!: number
    net_total!: number
    grand_total!: number
    total!: number
    rounded_total!: number
    total_taxes_and_charges!: number
    apply_discount_on!: string
    additional_discount_percentage!: number
    discount_amount!: number

    items!: CartItem[]
    cartOffers!: Offer[]

    constructor(config: ICart) {
        Object.assign(this, config);
    }

    static empty() {
        return new Cart(
            {
                validated: false,
                customer: "",
                customer_name: "",
                conversion_rate: 0,
                selling_price_list: "",
                currency: "",
                price_list_currency: "",
                total_qty: 0,
                net_total: 0,
                grand_total: 0,
                total: 0,
                rounded_total: 0,
                total_taxes_and_charges: 0,
                apply_discount_on: "",
                additional_discount_percentage: 0,
                discount_amount: 0,
                items: [],
                cartOffers: []
            }
        )
    }

    copyWith(config: Partial<ICart>): Cart {
        return new Cart(Object.assign({}, this, config));
    }

    static fromJson(json: any, publicCfg: PublicCfg | undefined = undefined): Cart | Cart[] {
        if(json instanceof Object) {
            json = JSON.stringify(json)
        }

        const parsedJson: Cart | Cart[] = JSON.parse(json)
        
        if(publicCfg) {
            // parsedJson["image"] = `${publicCfg.baseUrl}${parsedJson["image"]}` 
        }

        if(parsedJson instanceof Array) {
            let dataList = []
            for(let parsedItem of parsedJson) {
                // item.rate = item.price_list_rate
                parsedItem.items = CartItem.fromJson(parsedItem.items, publicCfg) as CartItem[]
                parsedItem.cartOffers = Offer.fromJson(parsedItem.cartOffers, publicCfg) as Offer[]

                dataList.push(
                    Cart.empty().copyWith({...parsedItem})
                )
            }
            return dataList
        } else {
            parsedJson.items = (CartItem.fromJson(parsedJson.items, publicCfg) as CartItem[])
            parsedJson.cartOffers = (Offer.fromJson(parsedJson.cartOffers, publicCfg) as Offer[])

            return Cart.empty().copyWith({...parsedJson})
        }
    }

    static toJson(data: Cart | Cart[]) {
        const jsonData = JSON.stringify(data)
        return jsonData
    }
}
