import { default as indexkepD4WGEqRMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/404/index.vue?macro=true";
import { default as index6lcqej1bT1Meta } from "/home/ubuntu/etms_product_catalog_frontend/pages/about-us/index.vue?macro=true";
import { default as addressesMvfgbTaSsfMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/account/addresses.vue?macro=true";
import { default as bank_45details7gMRNH1v4RMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/account/bank-details.vue?macro=true";
import { default as dashboardZo3jt0foHNMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/account/dashboard.vue?macro=true";
import { default as index28Fsu0fHelMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/account/index.vue?macro=true";
import { default as notificationsRp6AVDHq8FMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/account/notifications.vue?macro=true";
import { default as pointLOHRDdLER1Meta } from "/home/ubuntu/etms_product_catalog_frontend/pages/account/point.vue?macro=true";
import { default as refundAWueQvY0E5Meta } from "/home/ubuntu/etms_product_catalog_frontend/pages/account/refund.vue?macro=true";
import { default as walletg7k4M1mbrhMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/account/wallet.vue?macro=true";
import { default as indexWqs3kBdAiDMeta } from "/home/ubuntu/etms_product_catalog_frontend/lib/features/auth/pages/auth/confirm-email/index.vue?macro=true";
import { default as indexdMYvVKiMSWMeta } from "/home/ubuntu/etms_product_catalog_frontend/lib/features/auth/pages/auth/dashboard/index.vue?macro=true";
import { default as indexPRze1gx0JlMeta } from "/home/ubuntu/etms_product_catalog_frontend/lib/features/auth/pages/auth/forgot-password/index.vue?macro=true";
import { default as index5XOMH0WPK9Meta } from "/home/ubuntu/etms_product_catalog_frontend/lib/features/auth/pages/auth/login/index.vue?macro=true";
import { default as indexq88BolUfVTMeta } from "/home/ubuntu/etms_product_catalog_frontend/lib/features/auth/pages/auth/otp/index.vue?macro=true";
import { default as indexJxDVh7xTJEMeta } from "/home/ubuntu/etms_product_catalog_frontend/lib/features/auth/pages/auth/register/index.vue?macro=true";
import { default as index1F7YqgoLZHMeta } from "/home/ubuntu/etms_product_catalog_frontend/lib/features/auth/pages/auth/update-password/index.vue?macro=true";
import { default as _91slug_93jhsqhmAWZ8Meta } from "/home/ubuntu/etms_product_catalog_frontend/pages/blog/[slug].vue?macro=true";
import { default as index1nEvVNcxKyMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/blog/index.vue?macro=true";
import { default as _91customer_931LwhQl79YcMeta } from "/home/ubuntu/etms_product_catalog_frontend/lib/features/cart/pages/cart/[customer].vue?macro=true";
import { default as indexcFIvTHzp54Meta } from "/home/ubuntu/etms_product_catalog_frontend/lib/features/collections/pages/collections/index.vue?macro=true";
import { default as indexbPqcjd8eIsMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/compare/index.vue?macro=true";
import { default as indexZfyCqEYIpdMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/contact-us/index.vue?macro=true";
import { default as indexypSR9A2xrqMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/faq/index.vue?macro=true";
import { default as index8BdWMTvw0nMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/index.vue?macro=true";
import { default as indexQHqcw3KCyTMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/maintenance/index.vue?macro=true";
import { default as indexg7lFNEPmUsMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/offers/index.vue?macro=true";
import { default as indexg15KQqZqwuMeta } from "/home/ubuntu/etms_product_catalog_frontend/lib/features/order/pages/order/checkout/index.vue?macro=true";
import { default as _91name_93Smc9ZpKBRzMeta } from "/home/ubuntu/etms_product_catalog_frontend/lib/features/order/pages/order/details/[name].vue?macro=true";
import { default as indexIIanxWM9Z3Meta } from "/home/ubuntu/etms_product_catalog_frontend/lib/features/order/pages/order/orders/index.vue?macro=true";
import { default as _91order_number_93VWik097CcaMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/order/status/[order_number].vue?macro=true";
import { default as _91order_number_93A1qQGMxgKVMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/order/success/[order_number].vue?macro=true";
import { default as _91slug_93RXYQpD6LpwMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/pages/[slug].vue?macro=true";
import { default as index7Zp0XDWftcMeta } from "/home/ubuntu/etms_product_catalog_frontend/lib/features/part_request/pages/part_request/index.vue?macro=true";
import { default as _91name_93JDp9T45HtvMeta } from "/home/ubuntu/etms_product_catalog_frontend/lib/features/collections/pages/product/[name].vue?macro=true";
import { default as indexSkV90khPtaMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/search/index.vue?macro=true";
import { default as indexz6JIzrFNbUMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/seller/become-seller/index.vue?macro=true";
import { default as _91slug_93k09SnzFNYbMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/seller/store/[slug].vue?macro=true";
import { default as fruits_45marketANtRJ9B4z0Meta } from "/home/ubuntu/etms_product_catalog_frontend/pages/seller/store/fruits-market.vue?macro=true";
import { default as indexvDNIlNc9SwMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/seller/store/index.vue?macro=true";
import { default as indexmDq6s4vYCkMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/seller/stores/index.vue?macro=true";
import { default as indexs41l4E2YC2Meta } from "/home/ubuntu/etms_product_catalog_frontend/pages/theme/berlin/index.vue?macro=true";
import { default as indexbalM6XrqLjMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/theme/denver/index.vue?macro=true";
import { default as indexrEmwJNBl1SMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/theme/madrid/index.vue?macro=true";
import { default as index4bWfi0lbSUMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/theme/osaka/index.vue?macro=true";
import { default as indexfFiVQFVydTMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/theme/paris/index.vue?macro=true";
import { default as indexmnmOXxCDE5Meta } from "/home/ubuntu/etms_product_catalog_frontend/pages/theme/rome/index.vue?macro=true";
import { default as indexyHQPoMNRRuMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/theme/tokyo/index.vue?macro=true";
import { default as indexX8lMkG1xhOMeta } from "/home/ubuntu/etms_product_catalog_frontend/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/404/index.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/about-us/index.vue")
  },
  {
    name: "account-addresses",
    path: "/account/addresses",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/account/addresses.vue")
  },
  {
    name: "account-bank-details",
    path: "/account/bank-details",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/account/bank-details.vue")
  },
  {
    name: "account-dashboard",
    path: "/account/dashboard",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/account/dashboard.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/account/index.vue")
  },
  {
    name: "account-notifications",
    path: "/account/notifications",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/account/notifications.vue")
  },
  {
    name: "account-point",
    path: "/account/point",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/account/point.vue")
  },
  {
    name: "account-refund",
    path: "/account/refund",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/account/refund.vue")
  },
  {
    name: "account-wallet",
    path: "/account/wallet",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/account/wallet.vue")
  },
  {
    name: "auth-confirm-email",
    path: "/auth/confirm-email",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/lib/features/auth/pages/auth/confirm-email/index.vue")
  },
  {
    name: "auth-dashboard",
    path: "/auth/dashboard",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/lib/features/auth/pages/auth/dashboard/index.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/lib/features/auth/pages/auth/forgot-password/index.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/lib/features/auth/pages/auth/login/index.vue")
  },
  {
    name: "auth-otp",
    path: "/auth/otp",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/lib/features/auth/pages/auth/otp/index.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/lib/features/auth/pages/auth/register/index.vue")
  },
  {
    name: "auth-update-password",
    path: "/auth/update-password",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/lib/features/auth/pages/auth/update-password/index.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/blog/index.vue")
  },
  {
    name: "cart-customer",
    path: "/cart/:customer()",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/lib/features/cart/pages/cart/[customer].vue")
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/lib/features/collections/pages/collections/index.vue")
  },
  {
    name: "compare",
    path: "/compare",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/compare/index.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/contact-us/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/faq/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: indexQHqcw3KCyTMeta || {},
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/maintenance/index.vue")
  },
  {
    name: "offers",
    path: "/offers",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/offers/index.vue")
  },
  {
    name: "order-checkout",
    path: "/order/checkout",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/lib/features/order/pages/order/checkout/index.vue")
  },
  {
    name: "order-details-name",
    path: "/order/details/:name()",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/lib/features/order/pages/order/details/[name].vue")
  },
  {
    name: "order-orders",
    path: "/order/orders",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/lib/features/order/pages/order/orders/index.vue")
  },
  {
    name: "order-status-order_number",
    path: "/order/status/:order_number()",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/order/status/[order_number].vue")
  },
  {
    name: "order-success-order_number",
    path: "/order/success/:order_number()",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/order/success/[order_number].vue")
  },
  {
    name: "pages-slug",
    path: "/pages/:slug()",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/pages/[slug].vue")
  },
  {
    name: "part_request",
    path: "/part_request",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/lib/features/part_request/pages/part_request/index.vue")
  },
  {
    name: "product-name",
    path: "/product/:name()",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/lib/features/collections/pages/product/[name].vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/search/index.vue")
  },
  {
    name: "seller-become-seller",
    path: "/seller/become-seller",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/seller/become-seller/index.vue")
  },
  {
    name: "seller-store-slug",
    path: "/seller/store/:slug()",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/seller/store/[slug].vue")
  },
  {
    name: "seller-store-fruits-market",
    path: "/seller/store/fruits-market",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/seller/store/fruits-market.vue")
  },
  {
    name: "seller-store",
    path: "/seller/store",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/seller/store/index.vue")
  },
  {
    name: "seller-stores",
    path: "/seller/stores",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/seller/stores/index.vue")
  },
  {
    name: "theme-berlin",
    path: "/theme/berlin",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/theme/berlin/index.vue")
  },
  {
    name: "theme-denver",
    path: "/theme/denver",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/theme/denver/index.vue")
  },
  {
    name: "theme-madrid",
    path: "/theme/madrid",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/theme/madrid/index.vue")
  },
  {
    name: "theme-osaka",
    path: "/theme/osaka",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/theme/osaka/index.vue")
  },
  {
    name: "theme-paris",
    path: "/theme/paris",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/theme/paris/index.vue")
  },
  {
    name: "theme-rome",
    path: "/theme/rome",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/theme/rome/index.vue")
  },
  {
    name: "theme-tokyo",
    path: "/theme/tokyo",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/theme/tokyo/index.vue")
  },
  {
    name: "wishlist",
    path: "/wishlist",
    component: () => import("/home/ubuntu/etms_product_catalog_frontend/pages/wishlist/index.vue")
  }
]