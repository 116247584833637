const english = {
    "All Category": "All Category",
    "New": "New",
    "Hot": "Hot",
    "Home": "Home",
    "Paris": "Paris",
    "Tokyo": "Tokyo",
    "Osaka": "Osaka",
    "Rome": "Rome",
    "Madrid": "Madrid",
    "Berlin": "Berlin",
    "Denver": "Denver",
    "Help Center":"Help Center",
    "Email Address":"Email Address",
    "Theme Colo":"Theme Colo",
    "Collections": "Collections",
    "Collection Layouts": "Collection Layouts",
    "Collection Left Sidebar": "Collection Left Sidebar",
    "Collection Right Sidebar": "Collection Right Sidebar",
    "Collection No Sidebar": "Collection No Sidebar",
    "Collection 3 Grid": "Collection 3 Grid",
    "Collection 4 Grid": "Collection 4 Grid",
    "Collection 5 Grid": "Collection 5 Grid",
    "Collection List View": "Collection List View",
    "Category Slider": "Category Slider",
    "Category Sidebar": "Category Sidebar",
    "Category Banner": "Category Banner",
    "Offcanvas Filter": "Offcanvas Filter",
    "Product": "Product",
    "Product Pages": "Product Pages",
    "Product Thumbnail": "Product Thumbnail",
    "Product Images": "Product Images",
    "Product Slider": "Product Slider",
    "Product Sticky": "Product Sticky",
    "Product Accordion": "Product Accordion",
    "Product Tab": "Product Tab",
    "Product Features": "Product Features",
    "Bundle (Cross Sale)": "Bundle (Cross Sale)",
    "Hot Stock Progress": "Hot Stock Progress",
    "SOLD OUT": "SOLD OUT",
    "Sale Countdown": "Sale Countdown",
    "Product Zoom": "Product Zoom",
    "Product Variants Style": "Product Variants Style",
    "Variant Rectangle": "Variant Rectangle",
    "Variant Circle": "Variant Circle",
    "Variant Image Swatch": "Variant Image Swatch",
    "Variant Color": "Variant Color",
    "Variant Radio Button": "Variant Radio Button",
    "Variant Dropdown": "Variant Dropdown",
    "Sticky Checkout": "Sticky Checkout",
    "Dynamic Checkout": "Dynamic Checkout",
    "Secure Checkout": "Secure Checkout",
    "Active Product view": "Active Product view",
    "Active Last Orders": "Active Last Orders",
    "Product Simple": "Product Simple",
    "Product Classified": "Product Classified",
    "Size Chart": "Size Chart",
    "Delivery & Return": "Delivery & Return",
    "Payment Trust Badges": "Payment Trust Badges",
    "Ask an Expert": "Ask an Expert",
    "Product Tags": "Product Tags",
    "Product Information": "Product Information",
    "Social Share": "Social Share",
    "Related Products": "Related Products",
    "Wishlist & Compare": "Wishlist & Compare",
    "Features": "Features",
    "Theme Features": "Theme Features",
    "Sticky Compare": "Sticky Compare",
    "Cart Hover & Sticky": "Cart Hover & Sticky",
    "Cart Sidebar": "Cart Sidebar",
    "Cart Customize": "Cart Customize",
    "Email Template": "Email Template",
    "Welcome Template": "Welcome Template",
    "Abandonment": "Abandonment",
    "Offer Template": "Offer Template",
    "Order Success": "Order Success",
    "Reset Password": "Reset Password",
    "Recent Purchase Product": "Recent Purchase Product",
    "Newsletter": "Newsletter",
    "Quick View Modal": "Quick View Modal",
    "Exit Modal": "Exit Modal",
    "Invoice Template": "Invoice Template",
    "Invoice Template 1": "Invoice Template 1",
    "Invoice Template 2": "Invoice Template 2",
    "Invoice Template 3": "Invoice Template 3",
    "Blog": "Blog",
    "Blog Pages": "Blog Pages",
    "Blog List": "Blog List",
    "Grid Left Sidebar": "Grid Left Sidebar",
    "Grid Right Sidebar": "Grid Right Sidebar",
    "Grid No Sidebar": "Grid No Sidebar",
    "Blog Details": "Blog Details",
    "Pages": "Pages",
    "Authentication": "Authentication",
    "Sign In": "Sign In",
    "Sign Up": "Sign Up",
    "Forgot Password": "Forgot Password",
    "Verify OTP": "Verify OTP",
    "Update Password": "Update Password",
    "Account": "Account",
    "My Dashboard": "My Dashboard",
    "My Notifications": "My Notifications",
    "My Addresses": "My Addresses",
    "My Wallet": "My Wallet",
    "My Points": "My Points",
    "My Orders": "My Orders",
    "Order Details": "Order Details",
    "Refund History": "Refund History",
    "Payout Details": "Payout Details",
    "About Us": "About Us",
    "Browse FAQs": "Browse FAQs",
    "Cart": "Cart",
    "Checkout": "Checkout",
    "Compare": "Compare",
    "Contact Us": "Contact Us",
    "Maintenance": "Maintenance",
    "Offers": "Offers",
    "Search": "Search",
    "Wishlist": "Wishlist",
    "404": "404",
    "Seller": "Seller",
    "Become Seller": "Become Seller",
    "Seller Stores (Basic)": "Seller Stores (Basic)",
    "Seller Stores (Classic)": "Seller Stores (Classic)",
    "Store Details (Basic)": "Store Details (Basic)",
    "Store Details (Classic)": "Store Details (Classic)",
    "Categories": "Categories",
    "Shop Now": "Shop Now",
    "Trending Products": "Trending Products",
    "Saved Address": "Saved Address",
    "Add Address": "Add Address",
    "Address": "Address",
    "Pin Code": "Pin Code",
    "Phone": "Phone",
    "Edit": "Edit",
    "Remove": "Remove",
    "Bank Details": "Bank Details",
    "Bank Account No": "Bank Account No",
    "Enter Bank Account No": "Enter Bank Account No",
    "Bank Name": "Bank Name",
    "Enter Bank Name": "Enter Bank Name",
    "Holder Name": "Holder Name",
    "Enter Bank Holder Name": "Enter Bank Holder Name",
    "Swift": "Swift",
    "Enter Swift": "Enter Swift",
    "IFSC": "IFSC",
    "Enter IFSC": "Enter IFSC",
    "Paypal Details": "Paypal Details",
    "Paypal Email": "Paypal Email",
    "Enter Paypal Email": "Enter Paypal Email",
    "Save": "Save",
    "Hello": "Hello",
    "Welcome Text": "Welcome Text",
    "Balance": "Balance",
    "Total Points": "Total Points",
    "Total Orders": "Total Orders",
    "Profile Information": "Profile Information",
    "Name": "Name",
    "Login Details": "Login Details",
    "Email": "Email",
    "Password": "Password",
    "Notifications": "Notifications",
    "Order Number": "Order Number",
    "Pay Now": "Pay Now",
    "Invoice": "Invoice",
    "Image": "Image",
    "Price": "Price",
    "Quantity": "Quantity",
    "Subtotal": "Subtotal",
    "Action": "Action",
    "Ask For Refund": "Ask For Refund",
    "Consumer Details": "Consumer Details",
    "Billing Address": "Billing Address",
    "Shipping Address": "Shipping Address",
    "Shipping": "Shipping",
    "Delivery Slot": "Delivery Slot",
    "Payment Mode": "Payment Mode",
    "Payment Status": "Payment Status",
    "Summary": "Summary",
    "Shipping": "Shipping",
    "Tax": "Tax",
    "Points": "Points",
    "Wallet Balance": "Wallet Balance",
    "Coupon Discount": "Coupon Discount",
    "Total": "Total",
    "Order Date": "Order Date",
    "Total Amount": "Total Amount",
    "Status": "Status",
    "No": "No",
    "Date": "Date",
    "Amount": "Amount",
    "Payment Method": "Payment Method",
    "Option": "Option",
    "1 Point": "1 Point",
    "Remark": "Remark",
    "Transactions": "Transactions",
    "Refund": "Refund",
    "Reason": "Reason",
    "Created at": "Created at",
    "Dashboard": "Dashboard",
    "Earning Points": "Earning Points",
    "Logout": "Logout",
    "Show Menu": "Show Menu",
    "Welcome To Product-Catalog": "Welcome To Product-Catalog",
    "Forgot Your Password": "Forgot Your Password",
    "Email Address": "Email Address",
    "Email is required": "Email is required",
    "Invalid Email": "Invalid Email",
    "Send": "Send",
    "Register Your Account": "Register Your Account",
    "Login Your Account": "Login Your Account",
    "Password is required": "Password is required",
    "Remember me": "Remember me",
    "Log In": "Log In",
    "Or": "Or",
    "Don't have an account": "Don't have an account",
    "Please enter the one-time password to verify your account": "Please enter the one-time password to verify your account",
    "A code has been sent to": "A code has been sent to",
    "Validate": "Validate",
    "Welcome To Our Store": "Welcome To Our Store",
    "Create New Account": "Create New Account",
    "Full Name": "Full Name",
    "Name is required": "Name is required",
    "Phone Number": "Phone Number",
    "Phone number is required": "Phone number is required",
    "Password Confirmation": "Password Confirmation",
    "Confirm Password is required": "Confirm Password is required",
    "Confirm password does not match": "Confirm password does not match",
    "I agree with": "I agree with",
    "Terms": "Terms",
    "And": "And",
    "Privacy": "Privacy",
    "Already have an account": "Already have an account",
    "Reset Your Account Password": "Reset Your Account Password",
    "New password": "New password",
    "New Password is required": "New Password is required",
    "Confirm password": "Confirm password",
    "Submit": "Submit",
    "Recent Posts": "Recent Posts",
    "Tags": "Tags",
    "Featured": "Featured",
    "Read More": "Read More",
    "We make Organic Food In Market": "We make Organic Food In Market",
    "Just a few seconds to measure your body temperature. Up to 5 users! The battery lasts up to 2 years. There are many variations of passages of Lorem Ipsum available. We started in 2019 and haven't stopped smashing it since. A global brand that doesn't sleep, we are 24/7 and always bringing something new with over 100 new products dropping on the monthly, bringing you the latest looks for less.":
        "Just a few seconds to measure your body temperature. Up to 5 users! The battery lasts up to 2 years. There are many variations of passages of Lorem Ipsum available. We started in 2019 and haven't stopped smashing it since. A global brand that doesn't sleep, we are 24/7 and always bringing something new with over 100 new products dropping on the monthly, bringing you the latest looks for less.",
    "Free delivery for all orders": "Free delivery for all orders",
    "Only fresh foods": "Only fresh foods",
    "What We Do": "What We Do",
    "We are Trusted by Clients": "We are Trusted by Clients",
    "Our Creative Team": "Our Creative Team",
    "Product-Catalog team member": "Product-Catalog team member",
    "Latest Testimonials": "Latest Testimonials",
    "What people say": "What people say",
    "Our Blog": "Our Blog",
    "Our Latest Blog": "Our Latest Blog",
    "Get In Touch": "Get In Touch",
    "Enter Full Name": "Enter Full Name",
    "Subject": "Subject",
    "Enter Subject": "Enter Subject",
    "Subject is required": "Subject is required",
    "Message": "Message",
    "Enter Your Message": "Enter Your Message",
    "Message is required": "Message is required",
    "Send Message": "Send Message",
    "Enter Email Address": "Enter Email Address",
    "Enter Your Phone Number": "Enter Your Phone Number",
    "Copy Code": "Copy Code",
    "Search for products": "Search for products",
    "Sold By": "Sold By",
    "Product-Catalog": "Product-Catalog",
    "Unit": "Unit",
    "Saving": "Saving",
    "You Save": "You Save",
    "Save for later": "Save for later",
    "Cart Total": "Cart Total",
    "Process To Checkout": "Process To Checkout",
    "Return To Shopping": "Return To Shopping",
    "Cost at Checkout": "Cost at Checkout",
    "No Address Found": "No Address Found",
    "Select Timing Slot": "Select Timing Slot",
    "Add New": "Add New",
    "Delivery Options": "Delivery Options",
    "No Delivery Options Found": "No Delivery Options Found",
    "Payment Options": "Payment Options",
    "No Payment Options Found": "No Payment Options Found",
    "Not Calculated Yet": "Not Calculated Yet",
    "Would you prefer to pay using points?": "Would you prefer to pay using points?",
    "Would you prefer to pay using wallet?": "Would you prefer to pay using wallet?",
    "Have a Promo Code": "Have a Promo Code",
    "Enter Coupon Code Here": "Enter Coupon Code Here",
    "Apply": "Apply",
    "You Saved": "You Saved",
    "With this code": "With this code",
    "Coupon Applied": "Coupon Applied",
    "Place Order": "Place Order",
    "Filter Menu": "Filter Menu",
    "Sort By": "Sort By",
    "Filters": "Filters",
    "Clear All": "Clear All",
    "Back": "Back",
    "Rating": "Rating",
    "Star": "Star",
    "Discount": "Discount",
    "Availability": "Availability",
    "Review": "Review",
    "Weight": "Weight",
    "Move To Cart": "Move To Cart",
    "Order failed": "Order failed",
    "Payment Is Successfully And Your Order Is On The Way": "Payment Is Successfully And Your Order Is On The Way",
    "Payment Is Failed Please Try Again": "Payment Is Failed Please Try Again",
    "Qty": "Qty",
    "Price Details": "Price Details",
    "Shipping charges": "Shipping charges",
    "Wallet Point": "Wallet Point",
    "USD": "USD",
    "SALE": "SALE",
    "Guaranteed Safe Checkout": "Guaranteed Safe Checkout",
    "Please hurry! Only": "Please hurry! Only",
    "left in stock": "left in stock",
    "Ask a question": "Ask a question",
    "Frequently bought together": "Frequently bought together",
    "Product Selected for": "Product Selected for",
    "Add All To Cart": "Add All To Cart",
    "Customers Ordered": "Customers Ordered",
    "Active View in this item": "Active View in this item",
    "Off": "Off",
    "Add to Cart": "Add to Cart",
    "Delivery Details": "Delivery Details",
    "Description": "Description",
    "Q&A": "Q&A",
    "SKU": "SKU",
    "Stock Status": "Stock Status",
    "Gms": "Gms",
    "Items Left": "Items Left",
    "Review this product": "Review this product",
    "Let other customers know what you think": "Let other customers know what you think",
    "Write a review": "Write a review",
    "Edit Review": "Edit Review",
    "Share it": "Share it",
    "Q": "Q",
    "A": "A",
    "Hurry up! Sales Ends In": "Hurry up! Sales Ends In",
    "Days": "Days",
    "Hours": "Hours",
    "Min": "Min",
    "Sec": "Sec",
    "Contact": "Contact",
    "If You Any Query": "If You Any Query",
    "Visit Store": "Visit Store",
    "Products": "Products",
    "Follow Us": "Follow Us",
    "Sold": "Sold",
    "Items": "Items",
    "Hurry up offer end in": "Hurry up offer end in",
    "Offer Closed": "Offer Closed",
    "Subscribe": "Subscribe",
    "Code": "Code",
    "Useful Links": "Useful Links",
    "Quick Pages": "Quick Pages",
    "Customer Support 24/7": "Customer Support 24/7",
    "Download App": "Download App",
    "Stay connected": "Stay connected",
    "Menu": "Menu",
    "24/7 Support Center": "24/7 Support Center",
    "Unread messages": "Unread messages",
    "Shopping Cart": "Shopping Cart",
    "Spend": "Spend",
    "More and enjoy": "More and enjoy",
    "FREE SHIPPING": "FREE SHIPPING",
    "Congratulations": "Congratulations",
    "Enjoy free shipping on us": "Enjoy free shipping on us",
    "Your cart is currently empty": "Your cart is currently empty",
    "Shipping and taxes are calculated at checkout": "Shipping and taxes are calculated at checkout",
    "View Cart": "View Cart",
    "Deal Today": "Deal Today",
    "Hot Deals": "Hot Deals",
    "Hi": "Hi",
    "My Account": "My Account",
    "Register": "Register",
    "Log Out": "Log Out",
    "Get the App": "Get the App",
    "Loading": "Loading",
    "Edit Address": "Edit Address",
    "Title": "Title",
    "Enter Title": "Enter Title",
    "Title is required": "Title is required",
    "Enter Address": "Enter Address",
    "Street address is required": "Street address is required",
    "Country": "Country",
    "Select Country": "Select Country",
    "Country is required": "Country is required",
    "State": "State",
    "Select State": "Select State",
    "State is required": "State is required",
    "City": "City",
    "Enter City": "Enter City",
    "City is required": "City is required",
    "Pincode": "Pincode",
    "Enter Pincode": "Enter Pincode",
    "Pincode is required": "Pincode is required",
    "Enter Phone": "Enter Phone",
    "Phone number is invalid": "Phone number is invalid",
    "Cancel": "Cancel",
    "Change Password": "Change Password",
    "Current Password": "Current Password",
    "Enter Current Password": "Enter Current Password",
    "Current Password is required": "Current Password is required",
    "Enter New Password": "Enter New Password",
    "Enter Confirm Password": "Enter Confirm Password",
    "Confirmation": "Confirmation",
    "Are you sure you want to proceed": "Are you sure you want to proceed",
    "Yes": "Yes",
    "Recommended deals for you.": "Recommended deals for you.",
    "Delete Item": "Delete Item",
    "This Item Will Be Deleted Permanently. You Can't Undo This Action.": "This Item Will Be Deleted Permanently. You Can't Undo This Action.",
    "Edit Profile": "Edit Profile",
    "Enter Name": "Enter Name",
    "Email address is required": "Email address is required",
    "Please enter valid email": "Please enter valid email",
    "Wait": "Wait",
    "I'm sorry to see you go.": "I'm sorry to see you go.",
    "In order to earn 10% off your first purchase, provide your email address.": "In order to earn 10% off your first purchase, provide your email address.",
    "15%": "15%",
    "Want to make your purchase": "Want to make your purchase",
    "Plus, early access to new arrivals, exclusive sales, & lots more": "Plus, early access to new arrivals, exclusive sales, & lots more",
    "Select payment method is required": "Select payment method is required",
    "Product Details": "Product Details",
    "Your Questions": "Your Questions",
    "Enter Reason": "Enter Reason",
    "Reason is required": "Reason is required",
    "Select Payment Option": "Select Payment Option",
    "Payment type is required": "Payment type is required",
    "Rating is required": "Rating is required",
    "Review Content": "Review Content",
    "Write something": "Write something",
    "Update Item": "Update Item",
    "Add": "Add",
    "Someone Recently Purchased": "Someone Recently Purchased",
    "Minutes ago": "Minutes ago",
    "Color": "Color",
    "Theme Color": "Theme Color",
    "Dark": "Dark",
    "Light": "Light",
    "RTL": "RTL",
    "LTR": "LTR",
    "Choose": "Choose",
    "Buy Now": "Buy Now",
    "Let other customers know what you think.": "Let other customers know what you think.",
    "Have Doubts Regarding This Product": "Have Doubts Regarding This Product",
    "Post Your Question": "Post Your Question",
    "1": "1",
    "2": "2",
    "3": "3",
    "User": "User",
    "Cookies": "Cookies",
    "We use cookies to make your experience better": "We use cookies to make your experience better",
    "Privacy Policy": "Privacy Policy",
    "OK": "OK",
    "Welcome to Product-Catalog":"Welcome to Product-Catalog",
    "Reset your account password":"Reset your account password",
    "New Password":"New Password",
    "Confirm Password":"Confirm Password",
    "Password Confirmation":"Password Confirmation",
    "Notification":"Notification",
    "Welcome to your personalized My Account Dashboard. Here, you have the power to manage your entire e-commerce  experience in one place.Whether you're exploring the latest products, checking your wallet balance, or updating  your profile, everything is at your fingertips":"Welcome to your personalized My Account Dashboard. Here, you have the power to manage your entire e-commerce  experience in one place.Whether you're exploring the latest products, checking your wallet balance, or updating  your profile, everything is at your fingertips",
    "Order":"Order",
    "Created At":"Created At",
    "This Field Cannot Be Empty":"This Field Cannot Be Empty",
    "phone number is required.":"phone number is required.",
    "Frequently Asked Questions":"Frequently Asked Questions",
    "We are answering most frequent questions. No worries if you not find exact one. You can find out more by searching.":"We are answering most frequent questions. No worries if you not find exact one. You can find out more by searching.",
    "London Office":"London Office",
    "Bournemouth Office":"Bournemouth Office",
    "Copy Code ":"Copy Code ",
    " Search":" Search",
    "Select Timing Slot:":"Select Timing Slot:",
    //----------New -----------//
    "Minutes Ago":"Minutes Ago",
    "Category":"Category",
    "Recent Post":"Recent Post",
    "Email Address :":"Email Address :",
    "Become a Seller":"Become a Seller",
    "Loading...":"Loading...",
    "Free Shipping":"Free Shipping",
    "Hi , User ":"Hi , User ",
    "Forgot Password ?":"Forgot Password ?",
    "Please hurry! only":"Please hurry! only",
    "Delivery Return":"Delivery Return",
    "Ask a Question":"Ask a Question",
    " Add All To Cart":" Add All To Cart",
    "I am sorry to see you go":"I am sorry to see you go",
    "Log In Your Account":"Log In Your Account",
    "or":"or",
    "password":"password",
    "and":"and",
    "Forgot your password":"Forgot your password",
    "Email Adrress":"Email Adrress",
    "email":"email",
    // new add
    "Become a Seller":"Become a Seller",
    "Email Address":"Email Address",
    "Minutes Ago":"Minutes Ago",
    "Sold Out":"Sold Out",
    "FEATURED":"FEATURED",
    "subscribe":"subscribe",
    "All Categories":"All Categories",
    "Free Shipping":"Free Shipping",
    "Hi , User":"Hi , User",
    "items":"items"
};
export default english;