import type { PublicCfg } from "~/lib/core/plugins/services"

interface IOrderListItem {
    name: string
    docstatus: number
    customer: string
    status: string
    transaction_date: string
    currency: string
    total: number
}
  
export class OrderList implements IOrderListItem {
    name!: string
    docstatus!: number
    customer!: string
    status!: string
    transaction_date!: string
    currency!: string
    total!: number


    constructor(config: IOrderListItem) {
        Object.assign(this, config);
    }

    static empty() {
        return new OrderList(
            {
                name: "",
                docstatus: 0,
                customer: "",
                status: "Draft",
                transaction_date: "",
                currency: "",
                total: 0
            }
        )
    }

    copyWith(config: Partial<IOrderListItem>): OrderList {
        return new OrderList(Object.assign({}, this, config));
    }

    static fromJson(json: any, publicCfg: PublicCfg | undefined = undefined): OrderList | OrderList[] {
        if(json instanceof Object) {
            json = JSON.stringify(json)
        }

        const parsedJson: OrderList | OrderList[] = JSON.parse(json)

        if(publicCfg) {
            // parsedJson["image"] = `${publicCfg.baseUrl}${parsedJson["image"]}` 
        }

        if(parsedJson instanceof Array) {
            let dataList = []
            for(let item of parsedJson) {
                dataList.push(
                    OrderList.empty().copyWith({...item})
                )
            }
            return dataList
        } else {
            return OrderList.empty().copyWith({...parsedJson})
        }
    }

    static toJson(data: OrderList | OrderList[]) {
        const jsonData = JSON.stringify(data)
        return jsonData
    }
}
