
import type { NuxtApp } from "nuxt/app";
import { type Either, right, left } from "fp-ts/Either"

import HttpClient from "~/lib/core/http/http_client";
import { API_Endpoints } from "~/lib/core/configs/api_endpoints";
import { AppFailure, Failure, ServerFailure } from "~/lib/core/error/failure";
import { boolean } from "fp-ts";
import type { UserInfo } from "../models/user_info_model";
import { CustomStorage } from '~/lib/core/utils/custom_storage'
import { ProductList } from "../models/product_list_model";
import { Product } from "../models/product_model";
import type { PublicCfg } from "~/lib/core/plugins/services";
import type { CollectionFilters } from "../models/collection_filters";



export abstract class CollectionRepository {
    abstract fetchProduct(name: string): Promise<Either<Failure, Product>>
    abstract fetchProducts(filters: Object): Promise<Either<Failure, ProductList[]>>
    abstract fetchRelatedProducts(name: string): Promise<Either<Failure, ProductList[]>>
    abstract fetchProductGroups(): Promise<Either<Failure, string[]>>
    abstract fetchCategoryChild(parent: string): Promise<Either<Failure, string[]>>
    abstract fetchBrands(): Promise<Either<Failure, string[]>>


}


export class CollectionRepositoryImpl implements CollectionRepository {

    constructor(private _httpClient: HttpClient, private publicCfg: PublicCfg) { };

    async fetchProduct(name: string): Promise<Either<Failure, Product>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.collectionFetchProduct],
                    body: { name },
                    withAuth: true
                }
            )

            if (response.data.message.status == 200) {
                let product = Product.fromJson(response.data.message.data, this.publicCfg)
                return right(product as Product)
            } else {
                return left(new ServerFailure(response.data.message.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }

    async fetchProducts(filters: CollectionFilters): Promise<Either<Failure, ProductList[]>> {
        try {
            
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.collectionFetchProducts],
                    body: { filters: filters },
                    withAuth: true
                }
            )

            if (response.data.message.status == 200) {
                let products: ProductList[] = []

                response.data.message.data.forEach((_product: Object) => {
                    products.push(ProductList.fromJson(_product, this.publicCfg))
                })

                return right(products)
            } else {
                return left(new ServerFailure(response.data.message.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }

    async fetchRelatedProducts(name: string): Promise<Either<Failure, ProductList[]>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.collectionFetchRelatedProducts],
                    body: { name },
                    withAuth: false
                }
            )

            if (response.data.message.status == 200) {
                let products: ProductList[] = []

                response.data.message.data.forEach((_product: Object) => {
                    products.push(ProductList.fromJson(_product, this.publicCfg))
                })

                return right(products)
            } else {
                return left(new ServerFailure(response.data.message.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }

    
    async fetchProductGroups(): Promise<Either<Failure, string[]>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.searchItemGroups],
                    body: { },
                    withAuth: false
                }
            )

            if (response.data.message.status == 200) {
                let categories: string[] = []

                response.data.message.data.forEach((_category: string) => {
                    categories.push(_category)
                })

                return right(categories)
            } else {
                return left(new ServerFailure(response.data.message.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }

    async fetchCategoryChild(parent: string): Promise<Either<Failure, string[]>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.searchCategoryChild],
                    body: { parent },
                    withAuth: false
                }
            )

            if (response.data.message.status == 200) {
                let categories: string[] = []

                response.data.message.data.forEach((_category: string) => {
                    categories.push(_category)
                })

                return right(categories)
            } else {
                return left(new ServerFailure(response.data.message.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }

    async fetchBrands(): Promise<Either<Failure, string[]>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.searchBrands],
                    body: { },
                    withAuth: false
                }
            )

            if (response.data.message.status == 200) {
                let brands: any[] = []

                response.data.message.data.forEach((_brand: any) => {
                    brands.push({name: _brand["name"], value: false})
                })

                return right(brands)
            } else {
                return left(new ServerFailure(response.data.message.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }
}