import { fold } from "fp-ts/Either"

import { CartItem } from "~/lib/features/cart/data/models/cart_item_model"
import { Product } from "../../collections/data/models/product_model";
import type { AppFailure } from "~/lib/core/error/failure";
import type { Offer } from "../data/models/offer_model";
import { Cart } from "../data/models/cart_model";
// import { CartFilters } from "../data/models/cart_filters_model";
import { getStoreCartItem, makeCustomerCart } from "~/lib/core/utils/helpers";
import { set } from "@vueuse/core";
import { useCollectionStore } from "../../collections/store/collection_store";
import { useAuthStore } from "../../auth/store/auth_store";


export const useCartStore = defineStore("cartStore", () => {
  const collectionsStore = useCollectionStore()
  const { $repos, $popup } = useNuxtApp()
  
  let loading = ref<boolean>(false)
  let currentCartCustomer = ref("")
  let customersPanelsCarts = ref<string>("")

  // let cartFilters = ref<CartFilters>(CartFilters.empty().copyWith({ customer: "Imad Abdou  - 00964123456789", price_list: "End user" }))

  // let cart = ref<Cart>(Cart.empty())
  let carts = ref<Cart[]>([Cart.empty()])

  var modals = ref({ quickView: { show: false } });
  let cartToggle = ref(false)

  // rename later in sha allah
  async function loadStoredCart() {
    const result = await $repos.cartRepo.getStoredCartsLocal()
    fold(
      async (failure: AppFailure) => console.log("Failure: ", failure),
      async (_storedCarts: Cart[]) => {
        carts.value = _storedCarts
        // cart.value = carts.value[0]
      }
    )(result)
  }

  // async function applyCartFilters(filters: CartFilters) {
  //   cartFilters.value = cartFilters.value.copyWith(filters)
  //   refreshCart()
  // }

  async function applyOffers(offers: Offer[]) {
    debugger
    // cart.value.cartOffers = offers
    // refreshCart()
  }

  async function addProductToCart(product: Product, qty: number) {
    console.log(collectionsStore);
    
    if (!collectionsStore.collectionFilters.customer) {
      $popup.showPopup("Added To Cart Failed", `Please Select a Customer First.`)
      return
    }

    const result = await $repos.cartRepo.getCartItemFromProduct(product)

    fold(
      async (failure: AppFailure) => console.log(failure),
      async (newItem: CartItem) => {
        
        let cart = getCustomerCart(collectionsStore.collectionFilters.customer)

        if (!cart) {
          cart = makeCustomerCart(collectionsStore.collectionFilters.customer)
          carts.value.push(cart)
        }

        let exitingItem = getStoreCartItem(cart, newItem)

        if (exitingItem) {
          changeItemQty(cart, exitingItem!, exitingItem!.qty + qty)
          refreshCart(cart)
          $popup.showPopup(`Updated Cart (${cart.customer})`, `${exitingItem.item_name} Qty (${exitingItem.qty})`)
        } else {
          newItem.qty = qty
          cart.items.push(newItem)
          $popup.showPopup(`Added To Cart`, newItem.item_name)
          refreshCart(cart)
          toggleCartsDrawer(true)
          showCustomerCartPanel(cart.customer)

        }

        // $popup.showPopup(`Added To Cart (${cart.customer})`, newItem.item_name)
        // toggleCartsDrawer(true)
        // showCustomerCartPanel(cart.customer)
      }
    )(result)
  }

  function changeItemQty(cart: Cart, item: CartItem, qty: number) {
    if (cart) {
      item!.qty = qty

      if (item.qty < 1) {
        removeItemFromCart(cart, item)
      }

      if (cart.items.length < 1) {
        removeCustomerCart(cart.customer)
      }
      refreshCart(cart)
    }
  }

  function toggleCartsDrawer(show: boolean = false) {
    // if (show) {
    //   cartToggle.value = show
    // } else {
    //   cartToggle.value = !cartToggle.value
    // }
    cartToggle.value = !cartToggle.value

    if(useAuthStore().userInfo.is_epc_customer_user && cartToggle.value) {
      setTimeout(() => showCustomerCartPanel(collectionsStore.collectionFilters.customer), 500)
    }
    // if (show) {
    //   const cart = getCustomerCart(collectionsStore.collectionFilters.customer)
    //   if (cart) {
    //     refreshCart(cart)
    //   }
    // }
  }

  function showCustomerCartPanel(customer: string) {

    try {
      customersPanelsCarts.value = customer

    } catch (e) {
      console.log(e);
      debugger

    }
  }

  function getAppliedOffers(cart: Cart) {
    let total = 0
    cart.cartOffers.forEach((offer: Offer) => {
      total += offer.offer_applied ? 1 : 0
    })
    return total
  }

  const applyCartOffersDiscountsDebounce = useDebounceFn(async (cart: Cart) => {
    loading.value = true
    console.log("apply offers on: ", cart);

    cart.validated = false
    const result = await $repos.cartRepo.applyOffersDiscounts(toRaw(cart))

    fold(
      async (failure: AppFailure) => console.log("Failure: ", failure),
      async (_newCart: Cart) => {
        _newCart.validated = true
        const cartIdx = getCustomerCardIdx(_newCart.customer)
        if (cartIdx !== undefined) {
          set(carts.value, cartIdx, _newCart);
        }
        saveCartsLocal()
      }
    )(result)

    loading.value = false
  }, 1000)

  async function applyCartOffersDiscounts(cart: Cart) {
    console.log("applyCartOffersDiscounts", cart);

    applyCartOffersDiscountsDebounce(cart)

  }



  async function refreshCart(cart: Cart) {
    console.log("refreshCart: ", cart);

    setTimeout(() => {
      const isCartEmpty = getIsCartEmpty(cart);
      if (!isCartEmpty) {
        console.log("isCartEmpty", isCartEmpty);
        cart.validated = false

        setTimeout(() => {
          applyCartOffersDiscounts(cart)
        }, 500)
      }
    }, 500)

  }

  function removeItemFromCart(cart: Cart, item: CartItem) {
    const itemIdx = getStoreCartItemIdx(cart, item)
    cart.items.splice(itemIdx, 1)

    if (cart.items.length < 1) {
      removeCustomerCart(cart.customer)
    } else {
      refreshCart(cart)
    }
  };

  function removeCustomerCart(customer: string) {
    const cartIdx = getCustomerCardIdx(customer)

    if (cartIdx !== undefined) {
      carts.value.splice(cartIdx, 1)
      customersPanelsCarts.value = ""
      saveCartsLocal()
    }
  };

  function getCustomerCart(customer: string): Cart | undefined {
    const cart = carts.value.find((_cart: Cart) => {
      return _cart.customer === customer;
    })
    return cart
  }

  function getCustomerCardIdx(customer: string): number | undefined {
    let idx = carts.value.findIndex(cart => cart.customer === customer)
    return idx === -1 ? undefined : idx
  }

  const _debounceSaveCartsLocal = useDebounceFn(async () => {
    console.log("saveCartsLocal!!!");
    $repos.cartRepo.saveCartsLocal(toRaw(carts.value))

  }, 1000)

  async function saveCartsLocal() {
    _debounceSaveCartsLocal()
  }

  async function clearCart() {
    // cart.value = Cart.empty() 
    // cartFilters.value = CartFilters.empty()
    carts.value = []
    saveCartsLocal()
  }

  return {
    // vars
    currentCartCustomer,
    customersPanelsCarts,
    modals,
    // cartFilters,
    carts,
    cartToggle,
    
    // methods
    loadStoredCart,
    toggleCartsDrawer,
    addProductToCart,
    // applyCartFilters,
    applyOffers,
    getAppliedOffers,
    applyCartOffersDiscounts,
    refreshCart,
    clearCart,
    removeItemFromCart,
    removeCustomerCart,
    getCustomerCart,
    getCustomerCardIdx,
    changeItemQty,
    // toggleModal,

    // computed
    // isCartEmpty
  };
});