import type { PublicCfg } from "~/lib/core/plugins/services"
import { OrderItem } from "./order_item_model"

interface IOrder {
    name: string
    docstatus: number
    status: string
    customer: string
    customer_name: string
    transaction_date: string
    currency: string
    total: number
    total_taxes_and_charges: number
    in_words: string
    grand_total: number
    items: OrderItem[]
}

export class Order implements IOrder {
    name!: string
    docstatus!: number
    status!: string
    customer!: string
    customer_name!: string
    transaction_date!: string
    currency!: string
    total!: number
    total_taxes_and_charges!: number
    in_words!: string
    grand_total!: number
    items!: OrderItem[]

    constructor(config: IOrder) {
        Object.assign(this, config);
    }

    static empty() {
        return new Order(
            {
                name: "",
                docstatus: 0,
                status: "Draft",
                customer: "",
                customer_name: "",
                transaction_date: "",
                currency: "",
                total: 0,
                total_taxes_and_charges: 0,
                in_words: "",
                grand_total: 0,
                items: []
            }
        )
    }

    copyWith(config: Partial<IOrder>): Order {
        return new Order(Object.assign({}, this, config));
    }

    static fromJson(json: any, publicCfg: PublicCfg | undefined = undefined): Order | Order[] {
        if(json instanceof Object) {
            json = JSON.stringify(json)
        }

        const parsedJson: Order | Order[] = JSON.parse(json)
        
        if(parsedJson instanceof Array) {
            let dataList = []
            for(let item of parsedJson) {
                if(publicCfg) {
                    item.items = OrderItem.fromJson(item.items, publicCfg) as OrderItem[]
                }
                dataList.push(
                    Order.empty().copyWith({...item})
                )
            }
            return dataList
        } else {
            if(publicCfg) {
                parsedJson.items = OrderItem.fromJson(parsedJson.items, publicCfg) as OrderItem[]
            }
            return Order.empty().copyWith({...parsedJson})
        }
    }

    static toJson(data: Order | Order[]) {
        const jsonData = JSON.stringify(data)
        return jsonData
    }
}
