import type { NuxtApp } from "nuxt/app"
import type { PublicCfg } from "~/lib/core/plugins/services"
import type { ProductList } from "./product_list_model"
import type { PartCompat } from "./part_compat_model"

interface IProduct {
    item_code: string
    item_name: string
    item_group: string
    description: string
    stock_uom: string
    price_list_rate: number
    actual_qty: number
    reserved_qty: number
    brand: string
    image: string
    images: string[]
    epc_youtube_video_code: string
    epc_is_featured: boolean
    epc_on_sale: boolean
    epc_on_sale_discount_percentage: number
    related_products: ProductList[]
    epc_has_part_compatibility: number
    epc_part_compatibility: PartCompat[]
}
  
export class Product implements IProduct {
    item_code!: string
    item_name!: string
    item_group!: string
    description!: string
    stock_uom!: string
    price_list_rate!: number
    actual_qty!: number
    reserved_qty!: number
    brand!: string
    image!: string
    images!: string[]
    epc_youtube_video_code!: string
    epc_is_featured!: boolean
    epc_on_sale!: boolean
    epc_on_sale_discount_percentage!: number
    related_products!: ProductList[]
    epc_has_part_compatibility!: number
    epc_part_compatibility!: PartCompat[]



    constructor(config: IProduct) {
        Object.assign(this, config);
    }

    static empty() {
        return new Product(
            {
                item_code: "",
                item_name: "",
                item_group: "",
                description: "",
                stock_uom: "",
                price_list_rate: 0,
                actual_qty: 0,
                reserved_qty: 0,
                brand: "",
                image: "",
                images: [],
                epc_youtube_video_code: "",
                epc_is_featured: false,
                epc_on_sale: false,
                epc_on_sale_discount_percentage: 0,
                related_products: [],
                epc_has_part_compatibility: 0,
                epc_part_compatibility: []
            }
        )
    }
    
    copyWith(config: Partial<IProduct>): Product {
        return new Product(Object.assign({}, this, config));
    }
    
    static fromJson(json: any, publicCfg: PublicCfg | undefined = undefined): Product | Product[] {
        const IMAGES_FIELDNAMES = ["epc_image_1", "epc_image_2", "epc_image_3", "epc_image_4",]
        if(json instanceof Object) {
            json = JSON.stringify(json)
        }

        const parsedJson: Product | Product[] = JSON.parse(json)
        
        if(parsedJson instanceof Array) {
            let dataList = []
            for(let item of parsedJson) {
                if(publicCfg) {
                    item.image = `${publicCfg.baseUrl}${item.image}`
                    item.images.push(item.image)
                    IMAGES_FIELDNAMES.forEach(field=> {
                        debugger
                        let img = (parsedJson as any)[field]
                        if(img) {
                            item.images.push(`${publicCfg.baseUrl}${img}`)
                        }
                    })
                }
                dataList.push(
                    Product.empty().copyWith({...item})
                )
            }
            return dataList
        } else {
            let images: string[] = []
            if(publicCfg) {
                parsedJson.image = `${publicCfg.baseUrl}/${parsedJson.image}`
                images.push(parsedJson.image)

                IMAGES_FIELDNAMES.forEach(field=> {

                    let img = (parsedJson as any)[field]
                    if(img) {
                        images.push(`${publicCfg.baseUrl}${img}`)
                    }
                })
            }
            console.log(parsedJson);
            
            return Product.empty().copyWith({...parsedJson, images: images})
        }
    }

    static toJson(data: Product | Product[]) {
        const jsonData = JSON.stringify(data)
        return jsonData
    }
}
