import type { NuxtApp } from "nuxt/app"
import type { PublicCfg } from "~/lib/core/plugins/services"

interface IProductList {
    item_code: string
    item_name: string
    description: string
    stock_uom: string
    price_list_rate: number
    actual_qty: number
    image: string
    epc_is_featured: boolean
    epc_on_sale: boolean
    epc_on_sale_discount_percentage: number

}
  
export class ProductList implements IProductList {
    item_code!: string
    item_name!: string
    description!: string
    stock_uom!: string
    price_list_rate!: number
    actual_qty!: number
    image!: string
    epc_is_featured!: boolean
    epc_on_sale!: boolean
    epc_on_sale_discount_percentage!: number


    constructor(config: IProductList) {
        Object.assign(this, config);
    }

    static empty() {
        return new ProductList(
            {
                item_code: "",
                item_name: "",
                description: "",
                stock_uom: "",
                price_list_rate: 0,
                actual_qty: 0,
                image: "",
                epc_is_featured: false,
                epc_on_sale: false,
                epc_on_sale_discount_percentage: 0
            }
        )
    }

    copyWith(config: Partial<IProductList>): ProductList {
        return new ProductList(Object.assign({}, this, config));
    }

    static fromJson(json: any, publicCfg: PublicCfg): ProductList {
        if(publicCfg) {
            json["image"] = `${publicCfg.baseUrl}${json["image"]}` 
        }
        return new ProductList(Object.assign(this, {...json}))
    }

    static toJson(product: ProductList) {
        return JSON.stringify(product)
    }
}
