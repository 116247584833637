<template>
    <v-row justify="center" style="z-index: 100000;">
        <v-dialog v-model="dialog" width="20%" >
            <v-card :title="_title">
                <v-card-text>
                    <p>{{ _content }}</p>
                </v-card-text>

                <v-card-actions>
                    <v-btn color="var(--theme-color)" variant="text" @click="confirm">
                        {{ $t("yes") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="grey"  @click="reject">
                        {{ $t("no") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script setup>
const nuxt = useNuxtApp()

let dialog = ref(false)
let _title = ref("")
let _content = ref("")
let _cb = ref(null)

function confirm() {
    _cb.value()
    _cb.value = null;
    _title.value = ""
    _content.value = ""
    
    dialog.value = false;
}

function reject() {
    dialog.value = false;
    _cb.value = null;
    _title.value = ""
    _content.value = ""
}

nuxt.$listen("open_confirmation_dialog", ({title, content, cb}) => {

    _title.value = title
    _content.value = content
    _cb.value = cb;

    console.log("open", dialog.value);
    
    
    dialog.value = true;
})
</script>