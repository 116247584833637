export abstract class Failure extends Error {
    constructor(public readonly reason: string) {
        super(reason)
        // this.reason1 = reason
    }
}

export class AppFailure extends Failure {
    constructor(public readonly reason: string) {
        super(reason)
        this.reason = reason
        this.name = `AppFailure(${this.reason})`
    }

    // toString() {
    //     return `AppFailure(${this.reason})`
    // }
}

export class ServerFailure extends Failure {
    constructor(public readonly reason: string) {
        super(reason)
        this.reason = reason
        this.name = `ServerFailure(${this.reason})`

    }

    // toString() {
    //     return `ServerFailure(${this.reason})`
    // }
}
