import { $fetch, type FetchOptions } from 'ofetch';
import { defineNuxtPlugin, type NuxtApp } from 'nuxt/app';
import { AuthRepositoryImpl }  from '~/lib/features/auth/data/repository/auth_repository'
import HttpClient from '../http/http_client';
import { CollectionRepositoryImpl } from '~/lib/features/collections/data/repository/collections_repository';
import { CartRepositoryImpl } from '~/lib/features/cart/data/repository/cart_repository';
import { OrderRepositoryImpl } from '~/lib/features/order/data/repository/order_repository';
import { AppRepositoryImpl } from '../data/repository/app_repository';
import { PartRequestRepositoryImpl } from '~/lib/features/part_request/data/repository/part_request_repository';


interface IRepository {
  appRepo: AppRepositoryImpl
  authRepo: AuthRepositoryImpl
  collectionRepo: CollectionRepositoryImpl
  cartRepo: CartRepositoryImpl
  orderRepo: OrderRepositoryImpl
  partReqRepo: PartRequestRepositoryImpl
}

declare module "#app" {
  interface NuxtApp {
    $repos: IRepository;
  }
}
export interface PublicCfg {
  baseUrl: string
  baseApi: string
}

export default defineNuxtPlugin(async (nuxtApp: any) => {
  // init api client
  // await new Promise(resolve => setTimeout(() => resolve(null), 3000))
  const publicCfg = nuxtApp.$config.public;

  const apiFetcher = $fetch.create({
    baseURL: (nuxtApp.$config.public.baseApi as any),

  })
  const httpClient = new HttpClient(apiFetcher)
  
  const repos: IRepository = {
    appRepo: new AppRepositoryImpl(httpClient, publicCfg),
    authRepo: new AuthRepositoryImpl(httpClient, publicCfg),
    collectionRepo: new CollectionRepositoryImpl(httpClient, publicCfg),
    cartRepo: new CartRepositoryImpl(httpClient, publicCfg),
    orderRepo: new OrderRepositoryImpl(httpClient, publicCfg),
    partReqRepo: new PartRequestRepositoryImpl(httpClient, publicCfg)

  };

  return {
    provide: {
      repos: repos,
    },
  };
});