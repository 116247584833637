
import { type Either, right, left } from "fp-ts/Either"

import HttpClient from "~/lib/core/http/http_client";
import { API_Endpoints } from "~/lib/core/configs/api_endpoints";
import { AppFailure, Failure, ServerFailure } from "~/lib/core/error/failure";
import type { PublicCfg } from "~/lib/core/plugins/services";
import { EpcAds } from "../models/epc_ads_model";
import { RemoteConfig } from "../models/remote_config_model";



export abstract class AppRepository {
    abstract fetchAds(): Promise<Either<Failure, EpcAds[]>>
    abstract fetchRemoteConfig(): Promise<Either<Failure, RemoteConfig>>
}


export class AppRepositoryImpl implements AppRepository {

    constructor(private _httpClient: HttpClient, private publicCfg: PublicCfg) { };


    async fetchAds(): Promise<Either<Failure, EpcAds[]>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.appFetchAds],
                    body: { },
                    withAuth: false
                }
            )

            if (response.data.message.status == 200) {
                let ads: EpcAds[] = []

                response.data.message.data.forEach((_ad: object) => {
                    ads.push(EpcAds.fromJson(_ad, this.publicCfg) as EpcAds)
                })

                return right(ads)
            } else {
                return left(new ServerFailure(response.data.message.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }

    async fetchRemoteConfig(): Promise<Either<Failure, RemoteConfig>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.appFetchRemoteConfig],
                    body: { },
                    withAuth: false
                }
            )

            if (response.data.message.status == 200) {
                let remoteConfig = RemoteConfig.fromJson(response.data.message.data) as RemoteConfig
                return right(remoteConfig)
            } else {
                return left(new ServerFailure(response.data.message.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }
}