const french = {
    "All Category": "Toutes catégories",
    "New": "Nouveau",
    "Hot": "Populaire",
    "Home": "Accueil",
    "Paris": "Paris",
    "Tokyo": "Tokyo",
    "Osaka": "Osaka",
    "Rome": "Rome",
    "Madrid": "Madrid",
    "Berlin": "Berlin",
    "Denver": "Denver",
    "Collections": "Collections",
    "Collection Layouts": "Mises en page de collection",
    "Collection Left Sidebar": "Collection avec barre latérale gauche",
    "Collection Right Sidebar": "Collection avec barre latérale droite",
    "Collection No Sidebar": "Collection sans barre latérale",
    "Collection 3 Grid": "Collection 3 grilles",
    "Collection 4 Grid": "Collection 4 grilles",
    "Collection 5 Grid": "Collection 5 grilles",
    "Collection List View": "Vue en liste de la collection",
    "Category Slider": "Curseur de catégorie",
    "Category Sidebar": "Barre latérale de catégorie",
    "Category Banner": "Bannière de catégorie",
    "Offcanvas Filter": "Filtre hors toile",
    "Product": "Produit",
    "Product Pages": "Pages de produit",
    "Product Thumbnail": "Miniature de produit",
    "Product Images": "Images de produit",
    "Product Slider": "Curseur de produit",
    "Product Sticky": "Produit collant",
    "Product Accordion": "Accordéon de produit",
    "Product Tab": "Onglet de produit",
    "Product Features": "Caractéristiques du produit",
    "Bundle (Cross Sale)": "Pack (Vente croisée)",
    "Hot Stock Progress": "Progrès du stock populaire",
    "SOLD OUT": "ÉPUISÉ",
    "Sale Countdown": "Compte à rebours de vente",
    "Product Zoom": "Zoom produit",
    "Product Variants Style": "Style de variantes de produit",
    "Variant Rectangle": "Rectangle de variante",
    "Variant Circle": "Cercle de variante",
    "Variant Image Swatch": "Échantillon d'image de variante",
    "Variant Color": "Couleur de variante",
    "Variant Radio Button": "Bouton radio de variante",
    "Variant Dropdown": "Menu déroulant de variante",
    "Sticky Checkout": "Caisse collante",
    "Dynamic Checkout": "Caisse dynamique",
    "Secure Checkout": "Caisse sécurisée",
    "Active Product view": "Vue active du produit",
    "Active Last Orders": "Dernières commandes actives",
    "Product Simple": "Produit simple",
    "Product Classified": "Produit classé",
    "Size Chart": "Tableau des tailles",
    "Delivery & Return": "Livraison et retour",
    "Payment Trust Badges": "Badges de confiance en paiement",
    "Ask an Expert": "Demander à un expert",
    "Product Tags": "Étiquettes de produit",
    "Product Information": "Informations sur le produit",
    "Social Share": "Partage social",
    "Related Products": "Produits connexes",
    "Wishlist & Compare": "Liste de souhaits et comparaison",
    "Features": "Fonctionnalités",
    "Theme Features": "Fonctionnalités du thème",
    "Sticky Compare": "Comparaison collante",
    "Cart Hover & Sticky": "Survol du panier et collant",
    "Cart Sidebar": "Barre latérale du panier",
    "Cart Customize": "Personnalisation du panier",
    "Email Template": "Modèle d'e-mail",
    "Welcome Template": "Modèle de bienvenue",
    "Abandonment": "Abandon",
    "Offer Template": "Modèle d'offre",
    "Order Success": "Commande réussie",
    "Reset Password": "Réinitialiser le mot de passe",
    "Recent Purchase Product": "Produit récemment acheté",
    "Newsletter": "Bulletin d'information",
    "Quick View Modal": "Fenêtre d'aperçu rapide",
    "Exit Modal": "Fenêtre de sortie",
    "Invoice Template": "Modèle de facture",
    "Invoice Template 1": "Modèle de facture 1",
    "Invoice Template 2": "Modèle de facture 2",
    "Invoice Template 3": "Modèle de facture 3",
    "Blog": "Blog",
    "Blog Pages": "Pages du blog",
    "Blog List": "Liste de blog",
    "Grid Left Sidebar": "Grille avec barre latérale gauche",
    "Grid Right Sidebar": "Grille avec barre latérale droite",
    "Grid No Sidebar": "Grille sans barre latérale",
    "Blog Details": "Détails du blog",
    "Pages": "Pages",
    "Authentication": "Authentification",
    "Sign In": "Se connecter",
    "Sign Up": "S'inscrire",
    "Forgot Password": "Mot de passe oublié",
    "Verify OTP": "Vérifier le code OTP",
    "Update Password": "Mettre à jour le mot de passe",
    "Account": "Compte",
    "My Dashboard": "Mon tableau de bord",
    "My Notifications": "Mes notifications",
    "My Addresses": "Mes adresses",
    "My Wallet": "Mon portefeuille",
    "My Points": "Mes points",
    "My Orders": "Mes commandes",
    "Order Details": "Détails de la commande",
    "Refund History": "Historique des remboursements",
    "Payout Details": "Détails du paiement",
    "About Us": "À propos de nous",
    "Browse FAQs": "Parcourir les FAQ",
    "Cart": "Panier",
    "Checkout": "Paiement",
    "Compare": "Comparer",
    "Contact Us": "Contactez-nous",
    "Maintenance": "Maintenance",
    "Offers": "Offres",
    "Search": "Recherche",
    "Wishlist": "Liste de souhaits",
    "404": "404",
    "Seller": "Vendeur",
    "Become Seller": "Devenir vendeur",
    "Seller Stores (Basic)": "Magasins du vendeur (Basique)",
    "Seller Stores (Classic)": "Magasins du vendeur (Classique)",
    "Store Details (Basic)": "Détails du magasin (Basique)",
    "Store Details (Classic)": "Détails du magasin (Classique)",
    "Categories": "Catégories",
    "Shop Now": "Acheter maintenant",
    "Trending Products": "Produits tendance",
    "Saved Address": "Adresse enregistrée",
    "Add Address": "Ajouter une adresse",
    "Address": "Adresse",
    "Pin Code": "Code postal",
    "Phone": "Téléphone",
    "Edit": "Éditer",
    "Remove": "Supprimer",
    "Bank Details": "Détails bancaires",
    "Bank Account No": "Numéro de compte bancaire",
    "Enter Bank Account No": "Entrer le numéro de compte bancaire",
    "Bank Name": "Nom de la banque",
    "Enter Bank Name": "Entrer le nom de la banque",
    "Holder Name": "Nom du titulaire",
    "Enter Bank Holder Name": "Entrer le nom du titulaire du compte",
    "Swift": "Code SWIFT",
    "Enter Swift": "Entrer le code SWIFT",
    "IFSC": "Code IFSC",
    "Enter IFSC": "Entrer le code IFSC",
    "Paypal Details": "Détails Paypal",
    "Paypal Email": "Adresse e-mail Paypal",
    "Enter Paypal Email": "Entrer l'adresse e-mail Paypal",
    "Save": "Enregistrer",
    "Hello": "Bonjour",
    "Welcome Text": "Texte de bienvenue",
    "Balance": "Solde",
    "Total Points": "Total des points",
    "Total Orders": "Total des commandes",
    "Profile Information": "Informations de profil",
    "Name": "Nom",
    "Login Details": "Détails de connexion",
    "Email": "Email",
    "Password": "Mot de passe",
    "Notifications": "Notifications",
    "Order Number": "Numéro de commande",
    "Pay Now": "Payer maintenant",
    "Invoice": "Facture",
    "Image": "Image",
    "Price": "Prix",
    "Quantity": "Quantité",
    "Subtotal": "Sous-total",
    "Action": "Action",
    "Ask For Refund": "Demander un remboursement",
    "Consumer Details": "Détails du consommateur",
    "Billing Address": "Adresse de facturation",
    "Shipping Address": "Adresse de livraison",
    "Shipping": "Livraison",
    "Delivery Slot": "Créneau de livraison",
    "Payment Mode": "Mode de paiement",
    "Payment Status": "Statut du paiement",
    "Summary": "Résumé",
    "Shipping": "Livraison",
    "Tax": "Taxe",
    "Points": "Points",
    "Wallet Balance": "Solde du portefeuille",
    "Coupon Discount": "Remise du coupon",
    "Total": "Total",
    "Order Date": "Date de commande",
    "Total Amount": "Montant total",
    "Status": "Statut",
    "No": "Numéro",
    "Date": "Date",
    "Amount": "Montant",
    "Payment Method": "Moyen de paiement",
    "Option": "Option",
    "1 Point": "1 Point",
    "Remark": "Remarque",
    "Transactions": "Transactions",
    "Refund": "Remboursement",
    "Reason": "Raison",
    "Created at": "Créé le",
    "Dashboard": "Tableau de bord",
    "Earning Points": "Points gagnés",
    "Logout": "Déconnexion",
    "Show Menu": "Afficher le menu",
    "Welcome To Product-Catalog": "Bienvenue sur Product-Catalog",
    "Forgot Your Password": "Mot de passe oublié",
    "Email Address": "Adresse e-mail",
    "Email is required": "L'adresse e-mail est obligatoire",
    "Invalid Email": "E-mail invalide",
    "Send": "Envoyer",
    "Register Your Account": "Enregistrez votre compte",
    "Login Your Account": "Connectez-vous à votre compte",
    "Password is required": "Le mot de passe est obligatoire",
    "Remember me": "Se souvenir de moi",
    "Log In": "Se connecter",
    "Or": "Ou",
    "Don't have an account": "Vous n'avez pas de compte",
    "Please enter the one-time password to verify your account": "Veuillez entrer le mot de passe à usage unique pour vérifier votre compte",
    "A code has been sent to": "Un code a été envoyé à",
    "Validate": "Valider",
    "Welcome To Our Store": "Bienvenue dans notre magasin",
    "Create New Account": "Créer un nouveau compte",
    "Full Name": "Nom complet",
    "Name is required": "Le nom est obligatoire",
    "Phone Number": "Numéro de téléphone",
    "Phone number is required": "Le numéro de téléphone est obligatoire",
    "Password Confirmation": "Confirmation du mot de passe",
    "Confirm Password is required": "La confirmation du mot de passe est obligatoire",
    "Confirm password does not match": "La confirmation du mot de passe ne correspond pas",
    "I agree with": "Je suis d'accord avec",
    "Terms": "les conditions",
    "And": "et",
    "Privacy": "la vie privée",
    "Already have an account": "Vous avez déjà un compte",
    "Reset Your Account Password": "Réinitialiser votre mot de passe de compte",
    "New password": "Nouveau mot de passe",
    "New Password is required": "Le nouveau mot de passe est obligatoire",
    "Confirm password": "Confirmer le mot de passe",
    "Submit": "Soumettre",
    "Recent Posts": "Articles récents",
    "Tags": "Mots-clés",
    "Featured": "À la une",
    "Read More": "Lire la suite",
    "We make Organic Food In Market": "Nous fabriquons de la nourriture biologique sur le marché",
    "Just a few seconds to measure your body temperature. Up to 5 users! The battery lasts up to 2 years. There are many variations of passages of Lorem Ipsum available. We started in 2019 and haven't stopped smashing it since. A global brand that doesn't sleep, we are 24/7 and always bringing something new with over 100 new products dropping on the monthly, bringing you the latest looks for less.": 
    "Juste quelques secondes pour mesurer votre température corporelle. Jusqu'à 5 utilisateurs ! La batterie dure jusqu'à 2 ans. Il existe de nombreuses variations de passages du Lorem Ipsum disponibles. Nous avons commencé en 2019 et n'avons pas cessé de nous améliorer depuis. Une marque mondiale qui ne dort jamais, nous sommes disponibles 24/7 et apportons toujours quelque chose de nouveau, avec plus de 100 nouveaux produits chaque mois, vous offrant les derniers looks à moindre coût.",
    "Free delivery for all orders": "Livraison gratuite pour toutes les commandes",
    "Only fresh foods": "Seulement des aliments frais",
    "What We Do": "Ce que nous faisons",
    "We are Trusted by Clients": "Nous sommes approuvés par nos clients",
    "Our Creative Team": "Notre équipe créative",
    "Product-Catalog team member": "Membre de l'équipe Product-Catalog",
    "Latest Testimonials": "Derniers témoignages",
    "What people say": "Ce que disent les gens",
    "Our Blog": "Notre blog",
    "Our Latest Blog": "Notre dernier blog",
    "Get In Touch": "Contactez-nous",
    "Enter Full Name": "Entrez votre nom complet",
    "Subject": "Sujet",
    "Enter Subject": "Entrez le sujet",
    "Subject is required": "Le sujet est requis",
    "Message": "Message",
    "Enter Your Message": "Entrez votre message",
    "Message is required": "Le message est requis",
    "Send Message": "Envoyer le message",
    "Enter Email Address": "Entrez votre adresse e-mail",
    "Enter Your Phone Number": "Entrez votre numéro de téléphone",
    "Copy Code": "Copier le code",
    "Search for products": "Rechercher des produits",
    "Sold By": "Vendu par",
    "Product-Catalog": "Product-Catalog",
    "Unit": "Unité",
    "Saving": "Économie",
    "You Save": "Vous économisez",
    "Save for later": "Enregistrer pour plus tard",
    "Cart Total": "Total du panier",
    "Process To Checkout": "Procéder au paiement",
    "Return To Shopping": "Retour aux achats",
    "Cost at Checkout": "Coût à la caisse",
    "No Address Found": "Aucune adresse trouvée",
    "Select Timing Slot": "Sélectionner une plage horaire",
    "Add New": "Ajouter un nouveau",
    "Delivery Options": "Options de livraison",
    "No Delivery Options Found": "Aucune option de livraison trouvée",
    "Payment Options": "Options de paiement",
    "No Payment Options Found": "Aucune option de paiement trouvée",
    "Not Calculated Yet": "Pas encore calculé",
    "Would you prefer to pay using points?": "Préférez-vous payer avec des points ?",
    "Would you prefer to pay using a wallet?": "Préférez-vous payer avec un portefeuille ?",
    "Have a Promo Code": "Avez-vous un code promo",
    "Enter Coupon Code Here": "Entrez le code coupon ici",
    "Apply": "Appliquer",
    "You Saved": "Vous avez économisé",
    "With this code": "Avec ce code",
    "Coupon Applied": "Coupon appliqué",
    "Place Order": "Passer la commande",
    "Filter Menu": "Menu de filtre",
    "Sort By": "Trier par",
    "Filters": "Filtres",
    "Clear All": "Tout effacer",
    "Back": "Retour",
    "Rating": "Évaluation",
    "Star": "Étoile",
    "Discount": "Remise",
    "Availability": "Disponibilité",
    "Review": "Avis",
    "Weight": "Poids",
    "Move To Cart": "Ajouter au panier",
    "Order failed": "Échec de la commande",
    "Payment Is Successfully And Your Order Is On The Way": "Le paiement a réussi et votre commande est en cours de traitement",
    "Payment Is Failed Please Try Again": "Le paiement a échoué, veuillez réessayer",
    "Qty": "Qté",
    "Price Details": "Détails du prix",
    "Shipping charges": "Frais d'expédition",
    "Wallet Point": "Point de portefeuille",
    "USD": "USD",
    "SALE": "Vente",
    "Guaranteed Safe Checkout": "Paiement sécurisé garanti",
    "Please hurry! Only": "Faites vite ! Il ne reste que",
    "left in stock": "en stock",
    "Ask a question": "Poser une question",
    "Frequently bought together": "Fréquemment acheté ensemble",
    "Product Selected for": "Produit sélectionné pour",
    "Add All To Cart": "Ajouter tout au panier",
    "Customers Ordered": "Clients ayant commandé",
    "Active View in this item": "Vue active de cet article",
    "Off": "Remise",
    "Add to Cart": "Ajouter au panier",
    "Delivery Details": "Détails de livraison",
    "Description": "Description",
    "Q&A": "Questions et réponses",
    "SKU": "SKU",
    "Stock Status": "État des stocks",
    "Gms": "Gms",
    "Items Left": "Articles restants",
    "Review this product": "Donnez votre avis sur ce produit",
    "Let other customers know what you think": "Faites savoir aux autres clients ce que vous pensez",
    "Write a review": "Écrire un avis",
    "Edit Review": "Modifier l'avis",
    "Share it": "Partagez-le",
    "Q": "Q",
    "A": "R",
    "Hurry up! Sales Ends In": "Dépêchez-vous ! Fin des soldes dans",
    "Days": "Jours",
    "Hours": "Heures",
    "Min": "Min",
    "Sec": "Sec",
    "Contact": "Contact",
    "If You Any Query": "Si vous avez des questions",
    "Visit Store": "Visiter le magasin",
    "Products": "Produits",
    "Follow Us": "Suivez-nous",
    "Sold": "Vendu",
    "Items": "Articles",
    "Hurry up offer end in": "Dépêchez-vous, l'offre se termine dans",
    "Offer Closed": "Offre terminée",
    "Subscribe": "S'abonner",
    "Code": "Code",
    "Useful Links": "Liens utiles",
    "Quick Pages": "Pages rapides",
    "Customer Support 24/7": "Support client 24/7",
    "Download App": "Télécharger l'application",
    "Stay connected": "Restez connecté",
    "Menu": "Menu",
    "24/7 Support Center": "Centre de support 24/7",
    "Unread messages": "Messages non lus",
    "Shopping Cart": "Panier d'achat",
    "Spend": "Dépensez",
    "More and enjoy": "Plus et profitez",
    "FREE SHIPPING": "LIVRAISON GRATUITE",
    "Congratulations": "Félicitations",
    "Enjoy free shipping on us": "Profitez de la livraison gratuite offerte par nous",
    "Your cart is currently empty": "Votre panier est actuellement vide",
    "Shipping and taxes are calculated at checkout": "Les frais d'expédition et les taxes sont calculés à la caisse",
    "View Cart": "Voir le panier",
    "Deal Today": "Offre du jour",
    "Hot Deals": "Offres spéciales",
    "Hi": "Salut",
    "My Account": "Mon compte",
    "Register": "S'inscrire",
    "Log Out": "Se déconnecter",
    "Get the App": "Télécharger l'application",
    "Loading": "Chargement",
    "Edit Address": "Modifier l'adresse",
    "Title": "Titre",
    "Enter Title": "Entrez le titre",
    "Title is required": "Le titre est requis",
    "Enter Address": "Entrez l'adresse",
    "Street address is required": "L'adresse est requise",
    "Country": "Pays",
    "Select Country": "Sélectionnez le pays",
    "Country is required": "Le pays est requis",
    "State": "État",
    "Select State": "Sélectionnez l'État",
    "State is required": "L'État est requis",
    "City": "Ville",
    "Enter City": "Entrez la ville",
    "City is required": "La ville est requise",
    "Pincode": "Code postal",
    "Enter Pincode": "Entrez le code postal",
    "Pincode is required": "Le code postal est requis",
    "Enter Phone": "Entrez le numéro de téléphone",
    "Phone number is invalid": "Le numéro de téléphone est invalide",
    "Cancel": "Annuler",
    "Change Password": "Changer le mot de passe",
    "Current Password": "Mot de passe actuel",
    "Enter Current Password": "Entrez le mot de passe actuel",
    "Current Password is required": "Le mot de passe actuel est requis",
    "Enter New Password": "Entrez le nouveau mot de passe",
    "Enter Confirm Password": "Confirmez le mot de passe",
    "Confirmation": "Confirmation",
    "Are you sure you want to proceed": "Êtes-vous sûr de vouloir continuer",
    "Yes": "Oui",
    "Recommended deals for you.": "Offres recommandées pour vous.",
    "Delete Item": "Supprimer l'article",
    "This Item Will Be Deleted Permanently. You Can't Undo This Action.": "Cet article sera supprimé de manière permanente. Vous ne pouvez pas annuler cette action.",
    "Edit Profile": "Modifier le profil",
    "Enter Name": "Entrez le nom",
    "Email address is required": "L'adresse e-mail est requise",
    "Please enter a valid email": "Veuillez saisir une adresse e-mail valide",
    "Wait": "Attendre",
    "I'm sorry to see you go.": "Je suis désolé de vous voir partir.",
    "In order to earn 10% off your first purchase, provide your email address.": "Pour bénéficier de 10% de réduction sur votre premier achat, fournissez votre adresse e-mail.",
    "15%": "15%",
    "Want to make your purchase": "Envie de faire votre achat",
    "Plus, early access to new arrivals, exclusive sales, & lots more": "De plus, accès anticipé aux nouveautés, ventes exclusives, et bien plus encore",
    "Select payment method is required": "La sélection d'une méthode de paiement est requise",
    "Product Details": "Détails du produit",
    "Your Questions": "Vos questions",
    "Enter Reason": "Entrez la raison",
    "Reason is required": "La raison est requise",
    "Select Payment Option": "Sélectionnez l'option de paiement",
    "Payment type is required": "Le type de paiement est requis",
    "Rating is required": "La note est requise",
    "Review Content": "Contenu de l'avis",
    "Write something": "Écrivez quelque chose",
    "Update Item": "Mettre à jour l'article",
    "Add": "Ajouter",
    "Someone Recently Purchased": "Quelqu'un a récemment acheté",
    "Minutes ago": "Il y a quelques minutes",
    "Color": "Couleur",
    "Theme Color": "Couleur du thème",
    "Dark": "Sombre",
    "Light": "Clair",
    "RTL": "RTL",
    "LTR": "LTR",
    "Choose": "Choisir",
    "Buy Now": "Acheter maintenant",
    "Let other customers know what you think.": "Laissez les autres clients savoir ce que vous pensez.",
    "Have Doubts Regarding This Product": "Avez-vous des doutes concernant ce produit",
    "Post Your Question": "Posez votre question",
    "1": "1",
    "2": "2",
    "3": "3",
    "User": "Utilisateur",
    "Cookies": "Cookies",
    "We use cookies to make your experience better": "Nous utilisons des cookies pour améliorer votre expérience",
    "Privacy Policy": "Politique de confidentialité",
    "OK": "OK"
}
export default french;