import type { PublicCfg } from "~/lib/core/plugins/services"


interface IOffer {
    title: string
    min_qty: number
    max_qty: number
    min_amt: number
    max_amt: number
    auto_apply: boolean
    apply_type: string
    apply_item_code: string
    item_group: string
    discount_type: string
    rate: number
    discount_percentage: number
    discount_amount: number
    offer_applied: boolean
    ignore_offer: boolean
}
  
export class Offer implements IOffer {
    title!: string
    min_qty!: number
    max_qty!: number
    min_amt!: number
    max_amt!: number
    auto_apply!: boolean
    apply_type!: string
    apply_item_code!: string
    item_group!: string
    discount_type!: string
    rate!: number
    discount_percentage!: number
    discount_amount!: number
    offer_applied!: boolean
    ignore_offer!: boolean

    constructor(config: IOffer) {
        Object.assign(this, config);
    }

    static empty() {
        return new Offer(
            {
                title: "",
                min_qty: 0,
                max_qty: 0,
                min_amt: 0,
                max_amt: 0,
                auto_apply: false,
                apply_type: "",
                apply_item_code: "",
                item_group: "",
                discount_type: "",
                rate: 0,
                discount_percentage: 0,
                discount_amount: 0,
                offer_applied: false,
                ignore_offer: false
            }
        )
    }

    copyWith(config: Partial<IOffer>): Offer {
        return new Offer(Object.assign({}, this, config));
    }

    static fromJson(json: any, publicCfg: PublicCfg | undefined = undefined): Offer | Offer[] {
        if(json instanceof Object) {
            json = JSON.stringify(json)
        }

        const parsedJson: Offer | Offer[] = JSON.parse(json)
        
        if(publicCfg) {
            // parsedJson["image"] = `${publicCfg.baseUrl}${parsedJson["image"]}` 
        }

        if(parsedJson instanceof Array) {
            let dataList = []
            for(let item of parsedJson) {
                dataList.push(
                    Offer.empty().copyWith({...item})
                )
            }
            return dataList
        } else {
            return Offer.empty().copyWith({...parsedJson})
        }
    }

    static toJson(data: Offer | Offer[]) {
        const jsonData = JSON.stringify(data)
        return jsonData
    }
}
