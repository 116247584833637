import { defineStore } from "pinia";

import { fold } from "fp-ts/lib/Either";

import type { EpcAds } from "../data/models/epc_ads_model";
import type { Failure } from "../error/failure";
import { RemoteConfig } from "../data/models/remote_config_model";


export const useAppStore = defineStore("appStore", () => {
  const { $repos } =  useNuxtApp() 

  let loading = ref<boolean>(false)

  let loadedRemoteConfig = ref<RemoteConfig>(RemoteConfig.empty())
  let loadedAds = ref<EpcAds[]>([])
 

  async function loadRemoteConfig() {
    let result = await $repos.appRepo.fetchRemoteConfig()

    fold(
        async (failure: Failure) => console.log(failure),
        async (_remoteConfig: RemoteConfig) => {          
          loadedRemoteConfig.value = _remoteConfig
          loading.value = false
        }
      )(result)
  }


  async function loadAds() {
    loading.value = true
    let result = await $repos.appRepo.fetchAds()

    fold(
        async (failure: Failure) => console.log(failure),
        async (_ads: EpcAds[]) => {          
          loadedAds.value = _ads
          loading.value = false
        }
      )(result)
      loading.value = false
  }

return{
  // variables
  loading,
  loadedAds,
  loadedRemoteConfig,

  // methods
  loadRemoteConfig,
  loadAds
}
 
  });
      