export class API_Endpoints {

    static debug = false;
    
    static authLogin: string = "features.auth.auth.login"
    static authRegister: string = "features.auth.auth.register"
    static authConfirmRegistration: string = "features.auth.auth.confirm_registration"
    static authRequestConfirmKey: string = "features.auth.auth.request_confirmation_key"
    static authRequestResetPassword: string = "features.auth.auth.request_reset_password"
    static authSubmitResetPassword: string = "features.auth.auth.submit_reset_password"
    static authFetchUserInfo: string = "features.auth.auth.get_user_info"

    

    static authUpdateUserInfo: string = "features.auth.auth.update_user_info"

    static appFetchAds: string = "features.app.ads.fetch_ads"
    static appFetchRemoteConfig: string = "features.app.app.fetch_remote_config"


    static collectionFetchProduct: string = "features.collections.product.fetch_product"
    static collectionFetchProducts: string = "features.collections.product.fetch_products"
    static collectionFetchRelatedProducts: string = "features.collections.product.fetch_related_products"
    
    
    static orderFetchOrder: string = "features.order.order.fetch_order"
    static orderFetchOrders: string = "features.order.order.fetch_orders"
    static orderCreateOrder: string = "features.order.order.create_order"
    static orderUpdateOrder: string = "features.order.order.update_order"

    static offersFetchOffers: string = "features.offers.offers.fetch_offers"
    static offersApplyOffersDiscounts: string = "features.offers.offers.apply_offers_discounts"
    
    
    static searchCustomers: string = "features.search.customer.search_customers"
    static searchPriceLists: string = "features.search.price_list.search_price_lists"
    static searchItemGroups: string = "features.search.item_group.fetch_item_groups"
    static searchCategoryChild: string = "features.search.category.fetch_category_child"
    static searchBrands: string = "features.search.brand.fetch_brands"

    static searchPartMake: string = "features.search.veh_compat.search_part_makes"
    static searchPartModel: string = "features.search.veh_compat.search_part_models"
    static searchPartYear: string = "features.search.veh_compat.search_part_years"

    // Part Request
    static partRequestSubmitRequest: string = "features.part_request.part_request.submit_request"

}