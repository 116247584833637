import {VTreeview,VTreeviewGroup,VTreeviewItem} from 'vuetify/labs/VTreeview'

export const isDev = false
export function vuetifyConfiguration() {
  const options = {}
  
  
  options.components = {VTreeview,VTreeviewGroup,VTreeviewItem}
  
  return options
}

