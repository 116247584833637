import { type $Fetch, type FetchOptions, FetchError, type FetchResponse } from 'ofetch';
import { type NuxtApp } from 'nuxt/app';

const DEBUG = false

interface FrappeMessage<TMSG> {
  status?: number
  data: TMSG | null
  reason?: string,
  raw?: FetchResponse<any>
}

interface IPostParams {
  paths: Array<string>
  body?: object
  withAuth?: boolean
  extras?: FetchOptions
}

export default class HttpClient {
  private $fetch: $Fetch;
  public nuxtApp: NuxtApp;
  
  constructor(fetcher: $Fetch) {
    this.$fetch = fetcher;

    this.nuxtApp = useNuxtApp()
  }

  async get<T>({paths, withAuth = false, extras = {}}: IPostParams): Promise<FrappeMessage<T>> {
    // let url = paths.join("")
    let url = paths.join(".")

    extras["cache"] = "no-store"

    if(withAuth) {
      extras["headers"] = {
        "Authorization": `${this.nuxtApp.$pinia.state.value.authStore.userInfo.auth_token}`
      }
    }

    console.log("extras", extras);
    
    
    let $res
    
    try {
      $res = await this.$fetch.raw(url, { method: "GET", ...extras });

      if(DEBUG) {
        console.log("-------------------------------------------------");
        console.log("FACTORY GET: ", $res, " nuxtApp: ", this.nuxtApp);
        console.log("-------------------------------------------------");
      }

      if ($res.status == 401) {
        console.log("FACTORY GET: 401")
        this.nuxtApp.$pinia.state.value.authStore.logout()
        return {
          raw: $res,
          data: null,
          status: 401,
          reason: "unauthorized"
        }
      }
      
      
      return {
        raw: $res,
        //status: $res._data["status"],
        data: $res._data,
        reason: ""
        // raw: $res,
        // status: $res._data["message"]["status"],
        // data: $res._data["message"]["data"],
        // reason:  $res._data["message"]["data"]["reason"] ?? ""
      }

    } catch(e) {
      let error = e as FetchError
      console.log('error: ', error)
      if (error.status == 401) {
        console.log("FACTORY POST: 401")
        // useCustomStorage.write("session_user")
        this.nuxtApp.$router.replace("/auth/login");
        // this.nuxtApp.$toast.show_success_toast(`إنتهت صلاحية الدخول`);
      }
      return {
        raw: error.response,
        data: null,
        // status: 500,
        reason: e?.toString() ?? "http request error"
        // raw: $res,
        // data: null,
        // status: 500,
        // reason: e?.toString() ?? "http request error"
      }
    }
  }

  async post<T>({paths, body, withAuth = false, extras = {}}: IPostParams): Promise<FrappeMessage<T>> {
    // let url = paths.join("")
    let url = paths.join(".")
    
    extras["cache"] = "no-store"

    if(withAuth) {
      extras["headers"] = {
        "Authorization": `${this.nuxtApp.$pinia.state.value.authStore.userInfo.auth_token}`
      }
    }

    // const encryptedPayload = useEncryptor().encrypt(body)
    // console.log(encryptedPayload)
    
    let $res
    
    try {
      $res = await this.$fetch.raw(url, { method: "POST", body: body, ...extras });

      if(DEBUG) {
        console.log("-------------------------------------------------");
        console.log("FACTORY POST: ", $res, " nuxtApp: ", this.nuxtApp);
        console.log("-------------------------------------------------");
        console.log('success Res: ', $res)
      }
      return {
        raw: $res,
        //status: $res._data["status"],
        data: $res._data,
        reason: ""
      }

    } catch(e ) {
      let error = e as FetchError
      console.log(error.message)
      // this.nuxtApp.$loading.hide_loading()
      // this.nuxtApp.$toast.show_success_toast(`${error}`)

      
      if (error.status == 401 && $res?._data.message != "err_invalid_login2") {
        console.log("FACTORY POST: 401")
        // useCustomStorage.write("session_user")
        this.nuxtApp.$router.replace("/auth/login");
        // this.nuxtApp.$toast.show_error_toast(`إنتهت صلاحية الدخول`);
      }

      return {
        raw: error.response,
        data: null,
        // status: 500,
        reason: e?.toString() ?? "http request error"
      }
    }
  }
}