<template>
        <div class="left-sidebar-box">
            <div class="row">
                <div class="col-xl-12">
                    <div class="contact-image">
                        <img src="/images/inner-page/contact-us.png" class="img-fluid" alt="">
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="contact-title">
                        <h3>{{ $t("Get In Touch") }}</h3>
                    </div>

                    <div class="contact-detail">
                        <div class="row g-4">
                            <div class="col-xxl-6 col-lg-12 col-sm-6">
                                <div class="contact-detail-box">
                                    <div class="contact-icon">
                                        <Icon name="ri:phone-fill"></Icon>
                                    </div>
                                    <div class="contact-detail-title">
                                        <h4>{{ $t("Phone") }}</h4>
                                    </div>

                                    <div class="contact-detail-contain">
                                        <p>(+1) 618 190 496</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xxl-6 col-lg-12 col-sm-6">
                                <div class="contact-detail-box">
                                    <div class="contact-icon">
                                        <Icon name="ri:mail-fill"></Icon>
                                    </div>
                                    <div class="contact-detail-title">
                                        <h4>{{ $t("Email") }}</h4>
                                    </div>

                                    <div class="contact-detail-contain">
                                        <p>geweto9420@chokxus.com</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xxl-6 col-lg-12 col-sm-6">
                                <div class="contact-detail-box">
                                    <div class="contact-icon">
                                        <icon name="ri:map-pin-fill"></icon>
                                    </div>
                                    <div class="contact-detail-title">
                                        <h4>{{ $t("London Office") }}</h4>
                                    </div>

                                    <div class="contact-detail-contain">
                                        <p>Cruce Casa de Postas 29</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xxl-6 col-lg-12 col-sm-6">
                                <div class="contact-detail-box">
                                    <div class="contact-icon">
                                        <Icon name="ri:building-fill"></Icon>
                                    </div>
                                    <div class="contact-detail-title">
                                        <h4>{{ $t("Bournemouth Office") }}</h4>
                                    </div>

                                    <div class="contact-detail-contain">
                                        <p>Visitación de la Encina 22</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script setup>

</script>

<style lang="scss" scoped></style>