import type { CartItem } from "~/lib/features/cart/data/models/cart_item_model";
import { Cart } from "~/lib/features/cart/data/models/cart_model";

export function truncateText(txt: string, len: number) {
    if (txt.length > len) {
        return txt.substring(0, len) + "..";
    } else {
        return txt;
    }
}

export function formatCurrency(value: number, currency: string, discount: number = 0) {
    value = value - ((discount / 100) * value)
    let data = `${value.toFixed(2)} ${currency ? `(${currency})` : ''}`
    return data
}

export function getIsCartEmpty(cart: Cart): boolean {
    return cart.items.length < 1
}

export function makeCustomerCart(customer: string = ""): Cart {
    const cart = Cart.empty()
    return cart.copyWith({customer: customer})
}

// export function updateCustomerCart(customer: string, newCart: Cart, carts: Cart[]) {
//     let customerCart = getCustomerCart(customer, carts)
//     debugger
//     customerCart = newCart
// }

export function getStoreCartItem(cart: Cart, item: CartItem): CartItem | undefined {
    const cartItem = cart.items.find((_item: CartItem) => {
        return item.item_code === _item.item_code
    })
    return cartItem
}

export function getStoreCartItemByCode(cart: Cart, item_code: string): CartItem | undefined {
    const cartItem = cart.items.find((_item: CartItem) => {
        return item_code === _item.item_code
    })
    return cartItem
}

export function getStoreCartItemIdx(cart: Cart, item: CartItem): number {
    const idx = cart.items.findIndex((_item: CartItem) => {
        return item.item_code === _item.item_code
    })
    return idx
}