import type { NuxtApp } from "#app";

// interface IRepository {
//     appRepo: AppRepositoryImpl
//     authRepo: AuthRepositoryImpl
//     collectionRepo: CollectionRepositoryImpl
//     cartRepo: CartRepositoryImpl
//     orderRepo: OrderRepositoryImpl
//     partReqRepo: PartRequestRepositoryImpl
//   }
  
//   declare module "#app" {
//     interface NuxtApp {
//       $repos: IRepository;
//     }
//   }
  
export default defineNuxtPlugin((nuxtApp) => {

    function showPopup(title: string, content: string, timeout: number = 4000) {
        (nuxtApp as NuxtApp).$event("popup", {title, content, timeout})
    }

    return {
        provide: { popup: { showPopup, } },
    };
});