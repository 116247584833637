import { defineStore } from "pinia";
import { Product } from "../data/models/product_model";
import product from "~/server/api/product";
import { fold } from "fp-ts/lib/Either";
import type { Failure } from "~/lib/core/error/failure";
import { useDebounceFn } from '@vueuse/core'
import { CollectionFilters } from "../data/models/collection_filters";
import type { ProductList } from "../data/models/product_list_model";


export const useCollectionStore = defineStore("collectionStore", () => {
  const { $repos } =  useNuxtApp() 

  let loading = ref<boolean>(false)
  
  // let filter = ref({
  //   'page': 1,
  //   'paginate': 40,
  //   'status': 1,
  //   'field': '',
  //   'price': { min: 10000, max: 1000000 },
  //   'category': '',
  //   'tag': '',
  //   'sort': '',
  //   'sortBy': '',
  //   'rating': '',
  //   'attribute': '',
  //   'store_slug':'',
  //   'current_page':''
  // });
  
  let collectionFilters = ref(CollectionFilters.empty());

  let sortingOptions = ref([
    { display: "Low - High Price", value: 'low-high' },
    { display: "High - Low Price", value: 'high-low' },
    { display: "A - Z Order", value: 'a-z' },
    { display: "Z - A Order", value: 'z-a' },
    { display: "Ascendind Order", value: 'asc' },
    { display: "Decendind Order", value: 'dsc' },
    { display: "%Off-High To Low", value: 'discount-high-low' },
  ])

  let categories = ref([]),
  attribute = ref([])

  let filterObject = ref({
    selectedCategories:[],
    selectedAttributes:[],
    selectedPrice:[],
    selectedRating:[],
    sort:{display:"Ascendind Order", value:"asc"},
    field:'created_at',
    store_slug:{}
  })

  collectionFilters.value.sortBy = filterObject.value.sort
  collectionFilters.value.field = filterObject.value.field
  let allProducts = ref([])
  
  let loadedProduct = ref<Product>(Product.empty())
  let loadedProducts = ref<ProductList[]>([])
  let loadedRelatedProducts = ref<ProductList[]>([])
  let loadedProductGroups = ref<string[]>(["All Categories"])

  async function loadProduct(name: string) {
    loading.value = true
    let result = await $repos.collectionRepo.fetchProduct(name)

    fold(
        async (failure: Failure) => console.log(failure),
        async (_product: Product) => {
            loadedProduct.value = _product
            loading.value = false
            loadRelatedProducts(_product.item_code)
        }
    )(result)
  }

  async function loadProducts() {
    loading.value = true
    let result = await $repos.collectionRepo.fetchProducts(toRaw(collectionFilters.value))

    fold(
        async (failure: Failure) => console.log(failure),
        async (_products: ProductList[]) => {          
          loadedProducts.value = [..._products]
          loading.value = false
        }
      )(result)
      loading.value = false
  }

  async function loadRelatedProducts(name: string) {
    let result = await $repos.collectionRepo.fetchRelatedProducts(name)

    fold(
        async (failure: Failure) => console.log(failure),
        async (_relatedProducts: ProductList[]) => {
          loadedRelatedProducts.value = [..._relatedProducts]
        }
      )(result)
  }

  async function loadProductGroups() {
    let result = await $repos.collectionRepo.fetchProductGroups()

    fold(
        async (failure: Failure) => console.log(failure),
        async (_groups: string[]) => {
          // _groups.push("All Groups")
          loadedProductGroups.value = _groups
        }
      )(result)
  }

  async function loadCategoryChild() {
    let result = await $repos.collectionRepo.fetchCategoryChild("")

    fold(
        async (failure: Failure) => console.log(failure),
        async (_child: any) => {
          console.log("child", _child);
          
        }
      )(result)
  }

  function initialCheckedValue(){
    // filterObject.value.selectedCategories = useRoute().query.category? useRoute().query.category.split(','):[]
    // filterObject.value.selectedAttributes = useRoute().query.attribute? useRoute().query.attribute.split(','):[]
    // filterObject.value.selectedRating = useRoute().query.rating? useRoute().query.rating.split(','):[]
    // filterObject.value.selectedPrice = useRoute().query.price? useRoute().query.price.split(','):[]
    // console.log(filterObject.value, " | ", filter.value);
    
  }

  async function getProductById(id: string): Promise<Product | ProductList | undefined> {
    const product = loadedProducts.value.find((p) => {
      return id === p.item_code
    })
    return product
  }

  onMounted(() => {
    initialCheckedValue();
    loadProductGroups()
    loadCategoryChild()
  })

  function applyFilters(_collectionFilters: CollectionFilters) {
    collectionFilters.value = collectionFilters.value.copyWith({..._collectionFilters});
  }
  
  const fetchProductsDebounce = useDebounceFn(() => {
    loading.value = true
    setTimeout(() => loadProducts(), 800)
    
  }, 1000)


  function updateFilters(filters: CollectionFilters) {
    collectionFilters.value = collectionFilters.value.copyWith(filters)
    console.log("updated collection filters: ", toRaw(collectionFilters.value));
  }
  
  watch(collectionFilters, (prev, next) => { 
    fetchProductsDebounce()

  }, { deep: true, immediate: false })
  
  function getParams(){
    collectionFilters.value.page = useRoute().query.page ? useRoute().query.page : 1
    collectionFilters.value.field = useRoute().query.field? useRoute().query.field:'created_at'
    // collectionFilters.value.price = useRoute().query.price ? useRoute().query.price : { min: 10000, max: 2000000 }
    collectionFilters.value.category = useRoute().query.category ? useRoute().query.category : ''
    collectionFilters.value.tag = useRoute().query.tag?useRoute().query.tag:''
    collectionFilters.value.sort = useRoute().query.sort?useRoute().query.sort:''
    collectionFilters.value.sortBy = useRoute().query.sortBy?useRoute().query.sortBy:''
    collectionFilters.value.rating = useRoute().query.rating?useRoute().query.rating:''
    collectionFilters.value.attribute = useRoute().query.attribute?useRoute().query.attribute:'',
    collectionFilters.value.store_slug = useRoute().query.store_slug?useRoute().query.store_slug:''
  }
watchEffect(async() =>{
  getParams();
})

return{
  // variables
  loading,
  collectionFilters,
  categories,
  attribute,
  loadedProduct,
  loadedProducts,
  loadedRelatedProducts,
  loadedProductGroups,

  // methods
  loadProduct,
  loadProducts,
  loadProductGroups,
  getParams,
  getProductById,
  filterObject,
  allProducts,
  initialCheckedValue,
  updateFilters,
  applyFilters
}
 
  });
      