// import { useCartStore } from "./cart";
import { ref } from "vue"
import { fold } from "fp-ts/Either"
// import { defineStore } from "#imports"
import { Failure } from "@/lib/core/error/failure";
import { UserInfo } from "../data/models/user_info_model";
import { useCartStore } from "../../cart/store/cart_store";
import { useCollectionStore } from "../../collections/store/collection_store";

export const useAuthStore = defineStore("authStore", () => {
    const nuxt = useNuxtApp()
    const cartStore = useCartStore()
    const { $repos, $popup, $router } = nuxt

    let userInfo = ref<UserInfo>(UserInfo.empty())

    let isLoggedIn = ref(false)
    

    async function logout() {
        $repos.authRepo.logout()
        $popup.showPopup(`Thank You: ${userInfo.value.email}`, "Logged Out Successfully.")
        isLoggedIn.value = false
        userInfo.value = UserInfo.empty()
        cartStore.clearCart()
        setTimeout(()=> $router.push("/auth/login"), 2000)
    }
    
    async function login(email: string, password: string) {
        let result = await $repos.authRepo.login(email, password)

        fold(
            async (failure: Failure) => {
                if(failure.reason === "user_not_authenticated") {
                    $popup.showPopup(`Failure`, `Password wrong, Please try again or reset password`)
                    return
                }
                $popup.showPopup(`Failure`, `Something Went Wrong: (${failure.reason})`)
            },
            async (_userInfo: UserInfo) => {
                userInfo.value = _userInfo
                isLoggedIn.value = true
                $repos.authRepo.storeUser(_userInfo)
                $popup.showPopup(`Welcome Back: ${userInfo.value.email}`, "Logged In Successfully.")
                setTimeout(()=> location.href = "/", 2000)
            }
        )(result)
    }

    async function register(data: any) {
        let result = await $repos.authRepo.register(data)

        fold(
            async (failure: Failure) => {
                $popup.showPopup(`Failure`, failure.reason)
                if(failure.reason === "user_registered_but_not_verified") {
                    $popup.showPopup(`Registration`, `Email ${data.email} is not verified, A New key was sent to your Email`, 5000)
                    setTimeout(()=> $router.push(`/auth/confirm-email?email=${data.email}`), 8000)
                } else if(failure.reason === "user_already_registered_please_login") {
                    $popup.showPopup(`Registration`, `You are Already Registered, Please Login`, 5000)
                    // setTimeout(()=> $router.push("/auth/login"), 5000)
                }
            },
            async (_msg: string) => {
                $popup.showPopup(`Thanks: ${userInfo.value.email}`, "You Account Registered, Please Get Confirmation Key From Your Email.", 5000)
                setTimeout(()=> $router.push(`/auth/confirm-email?email=${data.email}`), 8000)
            }
        )(result)
    }
    
    async function loadUser() {
        let result = await $repos.authRepo.loadUser()

        fold(
            async (failure: Failure) => {
            },
            async (_userInfo: UserInfo) => {
                userInfo.value = _userInfo
                isLoggedIn.value = true
                useCollectionStore().collectionFilters.customer = _userInfo.customer_name 
                useCartStore().currentCartCustomer = _userInfo.customer_name
            }
        )(result)

        // then refresh user info from remote
        fetchUserInfo()
    }

    async function fetchUserInfo() {
        let result = await $repos.authRepo.fetchUserInfo()
        console.log("fetchUserInfo: ", result);
        
        fold(
            async (failure: Failure) => {
                console.log("failed update info", failure);
                
            },
            async (_userInfo: UserInfo) => {
                userInfo.value = _userInfo
                $repos.authRepo.storeUser(userInfo.value)
            }
        )(result)
    }
    
    async function updateUserInfo(newUserInfo: UserInfo) {
        let result = await $repos.authRepo.updateUserInfo(newUserInfo)

        fold(
            async (failure: Failure) => {
                console.log("failed update info", failure);
                
            },
            async (_userInfo: UserInfo) => {
                userInfo.value = _userInfo
                $repos.authRepo.storeUser(userInfo.value)
            }
        )(result)
    }
    
    return {
        userInfo,
        isLoggedIn,

        logout,
        login,
        register,
        loadUser,
        fetchUserInfo,
        updateUserInfo
    }
})
// export const useUserStore = defineStore("user-store", () => {
//   var User = ref({
//     isLoggedIn: false
//   }), UserInfo = ref({});
  
//   const setDefaultUser = () => {
//     if(process.client){
//     if(localStorage.getItem('usersAccount')){
//       User.value = JSON.parse(localStorage.getItem('usersAccount'))
//     }
//     else{
//       User.value.isLoggedIn = false
//     }
//    }
//   }
//   const SetUser = (user) => {
//     User.value = user;
//   }
//   const SetUserInfo = ({ user }) => {
//     UserInfo.value = user
//   }

//   const UpdateUserInfo = async () => {
//     let { data: user, error } = await useFetch('self', {
//     key: `GetSelfInfo`,
//     })
//     if (!error.value) {
//       UserInfo.value = user.value
//     }
//   }

//   const LogoutUser = async () => {
//       useCookie('uat').value = null;
//       UserInfo.value = {};
//       User.value.isLoggedIn = false;
//       localStorage.removeItem('usersAccount')
//       navigateTo('/')
  
//   }

//   const GetUserInfo = async () => {
//     await UpdateUserInfo();
//   };

//   return {
//     User,
//     UserInfo,
//     SetUser,
//     SetUserInfo,
//     LogoutUser,
//     UpdateUserInfo,
//     GetUserInfo,
//     setDefaultUser
//   };
// });