import type { PublicCfg } from "~/lib/core/plugins/services"

interface IRemoteConfig {
    maintenance_mode: boolean
    customer: string
    currency: string
    price_list: string
    enable_pwa_app_install: boolean
}
  
export class RemoteConfig implements IRemoteConfig {
    maintenance_mode!: boolean
    customer!: string
    currency!: string
    price_list!: string
    enable_pwa_app_install!: boolean


    constructor(config: IRemoteConfig) {
        Object.assign(this, config);
    }

    static empty() {
        return new RemoteConfig(
            {
                maintenance_mode: false,
                customer: "",
                currency: "",
                price_list: "",
                enable_pwa_app_install: false
            }
        )
    }

    copyWith(config: Partial<IRemoteConfig>): RemoteConfig {
        return new RemoteConfig(Object.assign({}, this, config));
    }

    static fromJson(json: any, publicCfg: PublicCfg | undefined = undefined): RemoteConfig | RemoteConfig[] {
        if(json instanceof Object) {
            json = JSON.stringify(json)
        }

        const parsedJson: RemoteConfig | RemoteConfig[] = JSON.parse(json)
        

        if(parsedJson instanceof Array) {
            let dataList = []
            for(let parsedItem of parsedJson) {
                dataList.push(RemoteConfig.empty().copyWith({...parsedItem}))
            }
            return dataList
        } else {
            return RemoteConfig.empty().copyWith({...parsedJson})
        }
    }

    static toJson(data: RemoteConfig | RemoteConfig[]) {
        const jsonData = JSON.stringify(data)
        return jsonData
    }
}
