import type { NuxtApp } from "nuxt/app"
import type { PublicCfg } from "~/lib/core/plugins/services"

interface ICollectionFilters {
    term: string
    customer: string
    page: number
    paginate: number,
    status: number
    field: string
    price: { min: number, max: number }
    stock_status: string
    item_group: string
    categories: string[]
    brands: string[]
    parent_category: string
    child_category_1: string
    child_category_2: string
    child_category_3: string
    child_category_4: string
    make: string
    model: string
    year: string
    tag: string
    sort: string
    sortBy: string
    rating: string
    attribute: string
    store_slug: string
    current_page: string
}
  
export class CollectionFilters implements ICollectionFilters {
    term!: string
    customer!: string
    page!: number
    paginate!: number
    status!: number
    field!: string
    price!: { min: number, max: number }
    stock_status!: string
    item_group!: string
    categories!: string[]
    brands!: string[]
    parent_category!: string
    child_category_1!: string
    child_category_2!: string
    child_category_3!: string
    child_category_4!: string
    make!: string
    model!: string
    year!: string
    tag!: string
    sort!: string
    sortBy!: string
    rating!: string
    attribute!: string
    store_slug!: string
    current_page!: string

    constructor(config: ICollectionFilters) {
        Object.assign(this, config);
    }

    static empty(): CollectionFilters {
        return new CollectionFilters(
            {
                term: "",
                customer: "",
                page: 1,
                paginate: 40,
                status: 1,
                field: "",
                price: { min: 10000, max: 2000000},
                stock_status: "0",
                item_group: "",
                categories: [],
                brands: [],
                parent_category: "",
                child_category_1: "",
                child_category_2: "",
                child_category_3: "",
                child_category_4: "",
                make: "",
                model: "",
                year: "",
                tag: "",
                sort: "",
                sortBy: "",
                rating: "",
                attribute: "",
                store_slug: "",
                current_page: ""
            }
        )
    }

    copyWith(config: Partial<ICollectionFilters>): CollectionFilters {
        return new CollectionFilters(Object.assign({}, this, config));
    }

    static fromJson(json: any, publicCfg: PublicCfg): CollectionFilters {
        // json["image"] = `${publicCfg.baseUrl}${json["image"]}` 
        return new CollectionFilters(Object.assign(this, {...json}))
    }

    static toJson(collectionFilters: CollectionFilters) {
        return JSON.stringify(collectionFilters)
    }
}
