
import { type Either, right, left } from "fp-ts/Either"

import HttpClient from "~/lib/core/http/http_client";
import { CustomStorage } from '~/lib/core/utils/custom_storage'
import { AppFailure, Failure, ServerFailure } from "~/lib/core/error/failure";
import type { PublicCfg } from "~/lib/core/plugins/services";
import { CartItem } from "../models/cart_item_model";
import type { Product } from "~/lib/features/collections/data/models/product_model";
import { Offer } from "../models/offer_model";
import { API_Endpoints } from "~/lib/core/configs/api_endpoints";
import { Invoice } from "../models/invoice_model";
import type { CartFilters } from "../models/cart_filters_model";



export abstract class PartRequestRepository {
    abstract submitRequest(data: any): Promise<Either<Failure, string>>
}


export class PartRequestRepositoryImpl implements PartRequestRepository {

    constructor(private _httpClient: HttpClient, private publicCfg: PublicCfg) { };

    async submitRequest(data: any): Promise<Either<Failure, string>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.partRequestSubmitRequest],
                    body: {...data},
                    withAuth: true
                }
            )
            console.log("response.data", response.data);
            
            if (response.data.message.status == 200) {
                return right(response.data.message.info)
            } else {
                return left(new ServerFailure(response.data.message.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }
}