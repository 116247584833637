import type { NuxtApp } from "nuxt/app"
import type { PublicCfg } from "~/lib/core/plugins/services"

interface IEpcAds {
    enabled: boolean
    title: string
    image: string
    content: string
}
  
export class EpcAds implements IEpcAds {
    enabled!: boolean
    title!: string
    image!: string
    content!: string


    constructor(config: IEpcAds) {
        Object.assign(this, config);
    }

    static empty() {
        return new EpcAds(
            {
                enabled: true,
                title: "",
                image: "",
                content: ""
            }
        )
    }
    
    copyWith(config: Partial<IEpcAds>): EpcAds {
        return new EpcAds(Object.assign({}, this, config));
    }
    
    static fromJson(json: any, publicCfg: PublicCfg | undefined = undefined): EpcAds | EpcAds[] {
        if(json instanceof Object) {
            json = JSON.stringify(json)
        }

        const parsedJson: EpcAds | EpcAds[] = JSON.parse(json)
        
        if(parsedJson instanceof Array) {
            let dataList = []
            for(let item of parsedJson) {
                if(publicCfg) {
                    item.image = `${publicCfg.baseUrl}${item.image}`
                }
                dataList.push(
                    EpcAds.empty().copyWith({...item})
                )
            }
            return dataList
        } else {
            if(publicCfg) {
                parsedJson.image = `${publicCfg.baseUrl}${parsedJson.image}`
            }
            
            return EpcAds.empty().copyWith({...parsedJson})
        }
    }

    static toJson(data: EpcAds | EpcAds[]) {
        const jsonData = JSON.stringify(data)
        return jsonData
    }
}
