import type { PublicCfg } from "~/lib/core/plugins/services"

interface ICartItem {
    item_code: string
    item_name: string
    item_group: string
    rate: number
    amount: number
    qty: number
    image: string
    stock_qty: number
    stock_uom: string
    price_list_rate: number
    margin_rate_or_amount: number
    rate_with_margin: number
    discount_percentage: number
    discount_amount: number
    net_rate: number
    net_amount: number
    warehouse: string
    actual_qty: number
    ordered_qty: number
    reserved_qty: number
}
  
export class CartItem implements ICartItem {
    item_code!: string
    item_name!: string
    item_group!: string
    rate!: number
    amount!: number
    qty!: number
    image!: string
    stock_qty!: number
    stock_uom!: string
    price_list_rate!: number
    margin_rate_or_amount!: number
    rate_with_margin!: number
    discount_percentage!: number
    discount_amount!: number
    net_rate!: number
    net_amount!: number
    warehouse!: string
    actual_qty!: number
    ordered_qty!: number
    reserved_qty!: number

    constructor(config: ICartItem) {
        Object.assign(this, config);
    }

    static empty() {
        return new CartItem(
            {
                item_code: "",
                item_name: "",
                item_group: "",
                rate: 0.0,
                amount: 0.0,
                qty: 1,
                image: "/images/product/placeholder.png",
                stock_qty: 0.0,
                stock_uom: "",
                price_list_rate: 0.0,
                margin_rate_or_amount: 0.0,
                rate_with_margin: 0.0,
                discount_percentage: 0.0,
                discount_amount: 0.0,
                net_rate: 0.0,
                net_amount: 0.0,
                warehouse: "",
                actual_qty: 0.0,
                ordered_qty: 0.0,
                reserved_qty: 0.0
            }
        )
    }

    copyWith(config: Partial<ICartItem>): CartItem {
        return new CartItem(Object.assign({}, this, config));
    }

    static fromJson(json: any, publicCfg: PublicCfg | undefined = undefined): CartItem | CartItem[] {
        if(json instanceof Object) {
            json = JSON.stringify(json)
        }

        const parsedJson: CartItem | CartItem[] = JSON.parse(json)
        
        if(publicCfg) {
            // parsedJson["image"] = `${publicCfg.baseUrl}${parsedJson["image"]}` 
        }
        if(parsedJson instanceof Array) {
            let dataList = []
            for(let item of parsedJson) {
                console.log(item);
                
                // item.rate = item.price_list_rate
                if(!item.image.startsWith("http")) {
                    item.image = `${publicCfg!.baseUrl}${item.image}`
                }
                dataList.push(
                    CartItem.empty().copyWith({...item})
                )
            }
            return dataList
        } else {
            // parsedJson.rate = parsedJson.price_list_rate
            parsedJson.image = `${publicCfg!.baseUrl}${parsedJson.image}`
            return CartItem.empty().copyWith({...parsedJson})
        }
    }

    static toJson(data: CartItem | CartItem[]) {
        const jsonData = JSON.stringify(data)
        return jsonData
    }
}
