
import { type Either, right, left } from "fp-ts/Either"

import HttpClient from "~/lib/core/http/http_client";
import { CustomStorage } from '~/lib/core/utils/custom_storage'
import { AppFailure, Failure, ServerFailure } from "~/lib/core/error/failure";
import type { PublicCfg } from "~/lib/core/plugins/services";
import { OrderList } from "~/lib/features/order/data/models/order_list_model";
import { Order } from "~/lib/features/order/data/models/order_model";
import { API_Endpoints } from "~/lib/core/configs/api_endpoints";
import { CartItem } from "~/lib/features/cart/data/models/cart_item_model";
import type { Cart } from "~/lib/features/cart/data/models/cart_model";


export abstract class OrderRepository {
    // abstract getOrderFromCart(cartItems: CartItem[]): Promise<Either<Failure, CartItem>>

    abstract fetchOrder(name: string): Promise<Either<Failure, Order>>
    abstract fetchOrders(filters: Object): Promise<Either<Failure, OrderList[]>>
    abstract createOrder(cart: Cart): Promise<Either<Failure, OrderList>>
}


export class OrderRepositoryImpl implements OrderRepository {

    constructor(private _httpClient: HttpClient, private publicCfg: PublicCfg) { };

    async fetchOrder(name: string): Promise<Either<Failure, Order>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.orderFetchOrder],
                    body: { name },
                    withAuth: true
                }
            )

            if (response.data.message.status == 200) {
                let order = Order.fromJson(response.data.message.data, this.publicCfg)
                
                return right(order as Order)
            } else {
                return left(new ServerFailure(response.data.message.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }

    async fetchOrders(filters: Object): Promise<Either<Failure, OrderList[]>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.orderFetchOrders],
                    body: { filters },
                    withAuth: true
                }
            )

            if (response.data.message.status == 200) {
                let orders: OrderList[] = []

                response.data.message.data.forEach((_order: Object) => {
                    orders.push(OrderList.fromJson(_order, this.publicCfg) as OrderList)
                })

                return right(orders)
            } else {
                return left(new ServerFailure(response.data.message.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }

    async createOrder(cart: Cart): Promise<Either<Failure, OrderList>> {
        try {    
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.orderCreateOrder],
                    body: { cart },
                    withAuth: true
                }
            )

            if (response.data.message.status == 200) {
                let order = Order.fromJson(response.data.message.data, this.publicCfg)

                return right(order as OrderList)
            } else {
                return left(new ServerFailure(response.data.message.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }
}



// async getCartItemFromProduct(product: Product): Promise<Either<Failure, CartItem>> {
//     try {
//         const cartItem = CartItem.empty().copyWith({
//             item_code: product.item_code,
//             item_name: product.item_name,
//             image: product.image,
//             price_list_rate: product.price_list_rate,
//             stock_uom: product.stock_uom

//         })
//         return right(cartItem)
//     } catch (e) {
//         return left(new AppFailure("failed to convert Product to CartItem"));
//     }
// }