interface IUserInfo {
    first_name: string
    last_name: string
    email: string
    phone1: string
    phone2: string
    currency: string
    user_image: string
    site_banner: string 
    is_epc_customer_user: boolean
    is_epc_team_member_user: boolean
    currencySymbol: string
    auth_token: string
    customer_name: string
    customer_default_price_list: string
    
    
}
  
export class UserInfo implements IUserInfo {
    first_name!: string
    last_name!: string
    email!: string
    phone1!: string
    phone2!: string
    currency!: string
    user_image!: string
    site_banner!: string 
    is_epc_customer_user!: boolean
    is_epc_team_member_user!: boolean
    currencySymbol!: string
    auth_token!: string
    customer_name!: string
    customer_default_price_list!: string

    constructor(iUserInfo: IUserInfo) {
        Object.assign(this, iUserInfo);
    }

    static empty() {
        return new UserInfo(
            {
                first_name: "",
                last_name: "",
                email: "",
                phone1: "",
                phone2: "",
                currency: "",
                user_image: "",
                site_banner: "",
                is_epc_customer_user: false,
                is_epc_team_member_user: false,
                currencySymbol: "",
                auth_token: "",
                customer_name: "",
                customer_default_price_list: ""
            }
        )
    }

    copyWith(iUserInfo: Partial<IUserInfo>): UserInfo {
        return new UserInfo(Object.assign({}, this, iUserInfo));
    }

    static fromJson(json: any): UserInfo {
        if(json instanceof Object) {
            json = JSON.stringify(json)
        }
        
        const parsedJson: UserInfo | UserInfo[] = JSON.parse(json)

        if(parsedJson instanceof Array) {
            let dataList = []
            for(let item of parsedJson) {
                // if(publicCfg) {
                //     item.userImage = `${publicCfg.baseUrl}${item.userImage!}` 
                // }
                dataList.push(
                    UserInfo.empty().copyWith({...item})
                )
            }
            return dataList
        } else {
            if(parsedJson.user_image) {
                parsedJson.user_image = `${parsedJson.user_image!}`
            } else {
                parsedJson.user_image = '/images/point_logo.png'
            }
            return UserInfo.empty().copyWith({...parsedJson})
        }
    }

    static toJson(iUserInfo: UserInfo) {
        return JSON.stringify(iUserInfo)
    }
}
