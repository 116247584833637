import type { NuxtApp } from "nuxt/app"
import type { PublicCfg } from "~/lib/core/plugins/services"

interface IOrderItem {
    item_code: string
    item_name: string
    delivery_date: string
    image: string
    qty: number
    uom: string
    rate: number
    amount: number
}
  
export class OrderItem implements IOrderItem {
    item_code!: string
    item_name!: string
    delivery_date!: string
    image!: string
    qty!: number
    uom!: string
    rate!: number
    amount!: number

    constructor(config: IOrderItem) {
        Object.assign(this, config);
    }

    static empty() {
        return new OrderItem(
            {
                item_code: "",
                item_name: "",
                delivery_date: "",
                image: "",
                qty: 0,
                uom: "",
                rate: 0,
                amount: 0,
            }
        )
    }

    copyWith(config: Partial<IOrderItem>): OrderItem {
        return new OrderItem(Object.assign({}, this, config));
    }

    static fromJson(json: any, publicCfg: PublicCfg | undefined = undefined): OrderItem | OrderItem[] {
        if(json instanceof Object) {
            json = JSON.stringify(json)
        }

        const parsedJson: OrderItem | OrderItem[] = JSON.parse(json)
        
        if(parsedJson instanceof Array) {
            let dataList = []
            for(let item of parsedJson) {
                if(publicCfg) {
                    item.image = `${publicCfg.baseUrl}${item.image}` 
                }

                dataList.push(
                    OrderItem.empty().copyWith({...item})
                )
            }
            return dataList
        } else {
            if(publicCfg) {
                parsedJson.image = `${publicCfg.baseUrl}${parsedJson.image}` 
            }
            return OrderItem.empty().copyWith({...parsedJson})
        }
    }

    static toJson(orderItem: OrderItem) {
        return JSON.stringify(orderItem)
    }
}
