<template>
  <section class="fresh-vegetable-section section-lg-space">
    <div class="container-fluid-lg">
      <div class="row gx-xl-5 gy-xl-0 g-3 ratio_148_1">
        <div class="col-xl-6 col-12">
          <div class="row g-sm-4 g-2">
            <div class="col-6">
              <div class="fresh-image-2">
                <div>
                  <img src="/images/inner-page/about-us/1.jpg" class="bg-img" alt="" />
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="fresh-image">
                <div>
                  <img src="/images/inner-page/about-us/2.jpg" class="bg-img" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-12">
          <div class="fresh-contain p-center-left">
            <div>
              <div class="review-title">
                <h4>{{ "About Us" }}</h4>
                <h2>{{ "We Make Organic Food In Market" }}</h2>
              </div>
              <div class="delivery-list">
                <p class="text-content">
                  {{ "Just a few seconds to measure your body temperature. Up to 5 users! The battery lasts up to 2 years. There are many variations of passages of Lorem Ipsum available.We started in 2019 and haven't stopped  smashing it since.A global brand that doesn't sleep, we are 24/7 and always bringing something new with  over 100 new products dropping on the monhtly, bringing you the latest looks for less."}}
                </p>
                <ul class="delivery-box">
                  <li>
                    <div class="delivery-box">
                      <div class="delivery-icon">
                        <img src="/svg/3/delivery.svg" class="" alt="" />
                      </div>
                      <div class="delivery-detail">
                        <h5 class="text">
                          {{ "Free delivery for all orders" }}
                        </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="delivery-box">
                      <div class="delivery-icon">
                        <img src="/svg/3/leaf.svg" class="" alt="" />
                      </div>
                      <div class="delivery-detail">
                        <h5 class="text">{{ "Only fresh foods" }}</h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="delivery-box">
                      <div class="delivery-icon">
                        <img src="/svg/3/delivery.svg" class="" alt="" />
                      </div>
                      <div class="delivery-detail">
                        <h5 class="text">
                          {{ "Free delivery for all orders" }}
                        </h5>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="delivery-box">
                      <div class="delivery-icon">
                        <img src="/svg/3/leaf.svg" class="" alt="" />
                      </div>
                      <div class="delivery-detail">
                        <h5 class="text">{{ "Only fresh foods" }}</h5>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup></script>
<style lang="scss" scoped></style>
