import revive_payload_client_4sVQNw7RlN from "/home/ubuntu/etms_product_catalog_frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_RMzWu406ID from "/home/ubuntu/etms_product_catalog_frontend/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_KgADcZ0jPj from "/home/ubuntu/etms_product_catalog_frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/ubuntu/etms_product_catalog_frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/ubuntu/etms_product_catalog_frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/ubuntu/etms_product_catalog_frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/ubuntu/etms_product_catalog_frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/ubuntu/etms_product_catalog_frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/ubuntu/etms_product_catalog_frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/ubuntu/etms_product_catalog_frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/ubuntu/etms_product_catalog_frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_Y6agwlnXZq from "/home/ubuntu/etms_product_catalog_frontend/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/home/ubuntu/etms_product_catalog_frontend/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import vuetify_no_client_hints_wMKVLl9fyU from "/home/ubuntu/etms_product_catalog_frontend/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import mitt_HiscZ3Q7Au from "/home/ubuntu/etms_product_catalog_frontend/lib/core/plugins/mitt.ts";
import popup_L35a2S9n8G from "/home/ubuntu/etms_product_catalog_frontend/lib/core/plugins/popup.ts";
import services_oeodkhB8WC from "/home/ubuntu/etms_product_catalog_frontend/lib/core/plugins/services.ts";
import nonSsrPlugins_OCpHeMnz51 from "/home/ubuntu/etms_product_catalog_frontend/plugins/nonSsrPlugins.js";
import directives_GDwrTGmylM from "/home/ubuntu/etms_product_catalog_frontend/plugins/directives.js";
import errorHandler_cfkZ75xwSQ from "/home/ubuntu/etms_product_catalog_frontend/plugins/errorHandler.js";
import i18n_sVHQBgnb3t from "/home/ubuntu/etms_product_catalog_frontend/plugins/i18n.js";
import ssrPlugins_cEKV0TUavU from "/home/ubuntu/etms_product_catalog_frontend/plugins/ssrPlugins.js";
import useBootstrap_client_ouDJvcVteA from "/home/ubuntu/etms_product_catalog_frontend/plugins/useBootstrap.client.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/home/ubuntu/etms_product_catalog_frontend/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_RMzWu406ID,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  vuetify_no_client_hints_wMKVLl9fyU,
  mitt_HiscZ3Q7Au,
  popup_L35a2S9n8G,
  services_oeodkhB8WC,
  nonSsrPlugins_OCpHeMnz51,
  directives_GDwrTGmylM,
  errorHandler_cfkZ75xwSQ,
  i18n_sVHQBgnb3t,
  ssrPlugins_cEKV0TUavU,
  useBootstrap_client_ouDJvcVteA,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]