<template>
  <v-row justify="center" style="z-index: 100000;">
    <v-col :sm="2" :md="2" lg="2">
      <v-dialog v-model="dialog" width="unset"  >
          <v-card title="PWA App Installer">

              <v-card-text>
                  <p>Install EPC Store Now and enjoy the Speed & benefits! (One Click Installer!)</p>
              </v-card-text>
              <hr></hr>

              <v-card-actions>
                  <v-btn color="var(--theme-color)" variant="outlined" @click="confirm">
                      {{ $t("yes") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" variant="outlined" @click="reject">
                      {{ $t("no") }}
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

    </v-col>
  </v-row>
</template>

<script setup>
const nuxt = useNuxtApp()

let dialog = ref(false)


function confirm() {

  nuxt.$pwa.install()
  dialog.value = false;
}

function reject() {
  dialog.value = false;

}

nuxt.$listen("open_pwa_install_dialog", async () => {
  console.log("open open_pwa_install_dialog done", dialog.value);
  if(nuxt.$pwa.showInstallPrompt) {
    dialog.value = true;
  }
})
</script>