<template>
  <div class="">

    <!-- <v-btn class="pe-2" prepend-icon="mdi-account-multiple-outline" variant="flat" @click="openDialog"></v-btn> -->


    <v-dialog v-model="dialog" max-width="500">
      <template v-slot:default="{ isActive }">
        <v-card rounded="lg">
          <v-card-title class="d-flex justify-space-between align-center">
            <div class="text-h5 text-medium-emphasis mt-2">
              <p style="color: var(--theme-color);" class="text-h5 ps-2">
                Barcode/QRCode Scanner
              </p>
            </div>

            <v-btn icon="mdi-close" variant="text" @click="closeDialog"></v-btn>
          </v-card-title>

          <v-divider class="mb-4"></v-divider>

          <v-card-text>
            <div id="qr-code-full-region"></div>
          </v-card-text>

          <v-divider class="mt-2"></v-divider>

          <v-card-actions class="my-2 d-flex justify-end">
            <v-btn class="text-none" rounded="xl" text="Cancel" @click="closeDialog"></v-btn>

            <v-btn class="text-none" style="color: var(--theme-color);" rounded="xl" text="Done" variant="flat"
              @click="doneDialog"></v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

  </div>
</template>

<script setup lang="ts">
import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode"
import { useCollectionStore } from "~/lib/features/collections/store/collection_store";
const collectionStore = useCollectionStore()

const { $listen } = useNuxtApp()
let html5QrcodeScanner = ref()

let dialog = ref(false)
var config = {
  fps: 60,
  qrbox : {
    width: 300,
    height: 150
  },
  supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA]
};

const openDialogDebounced = useDebounceFn(() => {

  dialog.value = true
  let beep = new Audio("/sounds/beep1.mp3")

  setTimeout(() => {
    html5QrcodeScanner.value = new Html5QrcodeScanner(
      "qr-code-full-region", config, false);
    html5QrcodeScanner.value.render(
      (barcode: any) => {
        console.log('success', barcode);
        beep.volume = 1
        beep.play()
        collectionStore.collectionFilters.term = barcode
        closeDialog()

      },
      (d1, d2) => {
        // console.log('error', d1, d2);
      }
    );
    console.log(toRaw(html5QrcodeScanner.value));

  }, 1000)
}, 1000)

function closeDialog() {
  console.log(html5QrcodeScanner);

  dialog.value = false
  html5QrcodeScanner.value.clear()
  console.log("close it");

}

function doneDialog() {
  html5QrcodeScanner.value.clear()
  console.log(html5QrcodeScanner);

  dialog.value = false
}

onMounted(() => {
  $listen("dialog:open_scanner", () => {
    if(dialog.value) return
    dialog.value = true

    openDialogDebounced()
  })
})

</script>
<style lang="scss" scoped></style>
