import mitt from 'mitt'

export default defineNuxtPlugin(() => {
  const emitter = mitt()

  return {
    provide: {
      event: emitter.emit,
      listen: emitter.on
    }
  }
})


// import mitt from 'mitt'

// const emitter = mitt()

// export default defineNuxtPlugin((nuxtApp) => {
//     // nuxtApp.vueApp.use();
//     return {
//         provide: { emitter },
//     };
// });